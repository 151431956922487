import React from 'react';
import { Edit2, Plus, Trash2 } from 'lucide-react';
import { ExpenseCategory, ExpenseSubcategory } from '../../types/expense';
import { useExpenseStore } from '../../store/useExpenseStore';
import toast from 'react-hot-toast';

interface ExpenseSubcategoryListProps {
  category: ExpenseCategory;
  onEdit: (subcategory: ExpenseSubcategory) => void;
  onAdd: () => void;
}

export function ExpenseSubcategoryList({ category, onEdit, onAdd }: ExpenseSubcategoryListProps) {
  const { deleteSubcategory } = useExpenseStore();

  const handleDelete = async (subcategory: ExpenseSubcategory) => {
    if (!window.confirm('Are you sure you want to delete this subcategory?')) {
      return;
    }
    try {
      await deleteSubcategory(subcategory.id, category.id);
      toast.success('Subcategory deleted successfully');
    } catch (error) {
      console.error('Error deleting subcategory:', error);
      toast.error('Failed to delete subcategory');
    }
  };

  return (
    <div className="mt-4 pl-8">
      <div className="flex items-center justify-between mb-2">
        <h4 className="text-sm font-medium text-gray-700">Subcategories</h4>
        <button
          onClick={onAdd}
          className="inline-flex items-center text-sm text-indigo-600 hover:text-indigo-900"
        >
          <Plus className="h-4 w-4 mr-1" />
          Add Subcategory
        </button>
      </div>
      <div className="space-y-2">
        {category.subcategories?.map((subcategory) => (
          <div
            key={subcategory.id}
            className="flex items-center justify-between py-2 px-3 bg-gray-50 rounded-md"
          >
            <div>
              <p className="text-sm font-medium text-gray-900">{subcategory.name}</p>
              {subcategory.description && (
                <p className="text-xs text-gray-500">{subcategory.description}</p>
              )}
            </div>
            <div className="flex items-center space-x-2">
              <span
                className={`px-2 py-1 text-xs font-medium rounded-full ${
                  subcategory.isActive
                    ? 'bg-green-100 text-green-800'
                    : 'bg-red-100 text-red-800'
                }`}
              >
                {subcategory.isActive ? 'Active' : 'Inactive'}
              </span>
              <button
                onClick={() => onEdit(subcategory)}
                className="p-1 text-indigo-600 hover:text-indigo-900"
              >
                <Edit2 className="h-4 w-4" />
              </button>
              <button
                onClick={() => handleDelete(subcategory)}
                className="p-1 text-red-600 hover:text-red-900"
              >
                <Trash2 className="h-4 w-4" />
              </button>
            </div>
          </div>
        ))}
        {(!category.subcategories || category.subcategories.length === 0) && (
          <p className="text-sm text-gray-500 italic">No subcategories found</p>
        )}
      </div>
    </div>
  );
}