import React from 'react';
import { LineChart, Line, PieChart, Pie, BarChart, Bar, ResponsiveContainer, XAxis, YAxis, Tooltip, Cell, Legend } from 'recharts';
import { useOrderStore } from '../../store/useOrderStore';
import { DateRangeType } from '../../types';
import { format, startOfDay, endOfDay } from 'date-fns';
import { formatCurrency } from '../../lib/utils';

interface SalesTrendReportProps {
  dateRange: DateRangeType;
  startDate: Date;
  endDate: Date;
  type: 'payment' | 'orderType' | 'menu';
  title: string;
}

const COLORS = ['#ff0000', '#006400', '#cc8800'];

export function SalesTrendReport({ dateRange, startDate, endDate, type, title }: SalesTrendReportProps) {
  const { activeOrders } = useOrderStore();

  const filteredOrders = activeOrders.filter(order => {
    try {
      const orderDate = new Date(order.createdAt);
      return orderDate >= startDate && orderDate <= endDate;
    } catch (error) {
      console.error('Error filtering order:', error);
      return false;
    }
  });

  const getData = () => {
    if (type === 'payment') {
      const paymentData = filteredOrders.reduce((acc, order) => {
        try {
          order.payments.forEach(payment => {
            const method = payment.method || 'cash';
            if (!acc[method]) {
              acc[method] = 0;
            }
            acc[method] += payment.amount;
          });
        } catch (error) {
          console.error('Error processing payment:', error);
        }
        return acc;
      }, {} as Record<string, number>);

      return Object.entries(paymentData).map(([name, value]) => ({
        name: name.toUpperCase(),
        value: Number(value.toFixed(2))
      }));
    }

    if (type === 'orderType') {
      const ordersByDate = filteredOrders.reduce((acc, order) => {
        try {
          const orderDate = new Date(order.createdAt);
          const dateKey = format(orderDate, dateRange === 'today' ? 'HH:mm' : 'MMM dd');
          if (!acc[dateKey]) {
            acc[dateKey] = {
              date: dateKey,
              regular: 0,
              party: 0,
              daily: 0
            };
          }
        } catch (error) {
          console.error('Error processing order:', error);
        }
        return acc;
      }, {} as Record<string, any>);

      return Object.values(ordersByDate).sort((a, b) => {
        if (dateRange === 'today') {
          return a.date.localeCompare(b.date);
        }
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      });
    }

    // Group by date for trend
    return filteredOrders
      .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
      .reduce((acc, order) => {
        const date = format(new Date(order.createdAt), dateRange === 'today' ? 'HH:mm' : 'MMM dd');
        try {
          const existingEntry = acc.find(entry => entry.date === date);
          const amount = Number(order.total.toFixed(2));
          
          if (existingEntry) {
            existingEntry.amount += amount;
            existingEntry.amount = Number(existingEntry.amount.toFixed(2));
          } else {
            acc.push({ date, amount });
          }
        } catch (error) {
          console.error('Error processing order:', error);
        }
        return acc;
      }, [] as Array<{ date: string; amount: number }>);
  };

  const data = getData();

  return (
    <div className="space-y-4">
      <h3 className="text-lg font-medium">{title}</h3>
      <div className="h-[400px]">
        {type === 'orderType' ? (
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 60 }}>
              <XAxis 
                dataKey="date"
                interval={0}
                angle={dateRange !== 'today' ? -45 : 0}
                textAnchor={dateRange !== 'today' ? 'end' : 'middle'}
                height={60}
              />
              <YAxis allowDecimals={false} />
              <Tooltip />
              <Legend />
              <Bar dataKey="regular" stackId="a" fill="#ff0000" name="Regular" />
              <Bar dataKey="party" stackId="a" fill="#cc8800" name="Party" />
              <Bar dataKey="daily" stackId="a" fill="#006400" name="Daily" />
            </BarChart>
          </ResponsiveContainer>
        ) : type === 'menu' ? (
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={data}>
              <XAxis 
                dataKey="date"
                interval={0}
                angle={dateRange !== 'today' ? -45 : 0}
                textAnchor={dateRange !== 'today' ? 'end' : 'middle'}
                height={60}
              />
              <YAxis />
              <Tooltip formatter={(value) => formatCurrency(value as number)} />
              <Line 
                type="monotone" 
                dataKey="amount" 
                stroke="#ff0000"
                strokeWidth={2}
                dot={dateRange !== 'today'}
              />
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                labelLine={true}
                label={({ name, value, percent }) => 
                  `${name} (${formatCurrency(value)} - ${(percent * 100).toFixed(0)}%)`
                }
                outerRadius={100}
                fill="#8884d8"
                dataKey="value"
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip formatter={(value) => formatCurrency(value as number)} />
            </PieChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
}