import React from 'react';
import { DailyExpense } from '../../types/expense';
import { formatCurrency } from '../../lib/utils';
import { format } from 'date-fns';
import { LineChart, Line, ResponsiveContainer, XAxis, YAxis, Tooltip } from 'recharts';
import { Receipt } from 'lucide-react';

interface DailyExpenseSummaryProps {
  expenses: DailyExpense[];
  startDate: Date;
  endDate: Date;
}

export function DailyExpenseSummary({ expenses, startDate, endDate }: DailyExpenseSummaryProps) {
  const totalAmount = expenses.reduce((sum, exp) => sum + exp.amount, 0);
  const averageAmount = expenses.length > 0 ? totalAmount / expenses.length : 0;

  // Prepare data for chart
  const chartData = expenses
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
    .map(expense => ({
      date: format(new Date(expense.date), 'MMM dd'),
      amount: expense.amount
    }));

  return (
    <div className="bg-white shadow-sm rounded-xl divide-y divide-gray-200 border border-gray-100">
      <div className="p-6">
        <h3 className="text-lg font-semibold text-gray-900 mb-4 flex items-center gap-2">
          <Receipt className="h-5 w-5 text-indigo-600" />
          Overview
        </h3>
        <dl className="mt-5 grid grid-cols-1 gap-5">
          <div className="bg-gradient-to-br from-indigo-50 to-indigo-100/50 px-6 py-4 rounded-lg border border-indigo-100">
            <dt className="text-sm font-medium text-indigo-600">Total Expenses</dt>
            <dd className="mt-1 text-3xl font-bold text-indigo-700">
              {formatCurrency(totalAmount)}
            </dd>
            <dd className="text-sm text-indigo-600 mt-1">
              {expenses.length} expenses recorded
            </dd>
          </div>
          <div className="bg-gradient-to-br from-emerald-50 to-emerald-100/50 px-6 py-4 rounded-lg border border-emerald-100">
            <dt className="text-sm font-medium text-emerald-600">Daily Average</dt>
            <dd className="mt-1 text-3xl font-bold text-emerald-700">
              {formatCurrency(averageAmount)}
            </dd>
            <dd className="text-sm text-emerald-600 mt-1">
              Per expense average
            </dd>
          </div>
        </dl>
      </div>

      <div className="p-6">
        <h4 className="text-sm font-medium text-gray-900 mb-4">Expense Trend</h4>
        <div className="h-[200px]">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={chartData}>
              <XAxis 
                dataKey="date"
                interval={0}
                angle={-45}
                textAnchor="end"
                height={60}
              />
              <YAxis />
              <Tooltip formatter={(value) => formatCurrency(value as number)} />
              <Line 
                type="monotone" 
                dataKey="amount" 
                stroke="#4f46e5"
                strokeWidth={2}
                dot={true}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}