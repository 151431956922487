import React, { useState, useEffect } from 'react';
import { useExpenseStore } from '../../store/useExpenseStore';
import { useOutletStore } from '../../store/useOutletStore';
import { format } from 'date-fns';
import { Filter, Download, ArrowLeft, ArrowUp, ArrowDown } from 'lucide-react';
import { Link } from 'react-router-dom';
import { formatCurrency } from '../../lib/utils';

type SortField = 'date' | 'category' | 'subcategory' | 'amount';
type SortOrder = 'asc' | 'desc';

export function ExpenseTransactionList() {
  const { expenses, categories, types, fetchExpenses, fetchCategories, fetchTypes } = useExpenseStore();
  const { currentOutlet } = useOutletStore();
  const [selectedTypeFilter, setSelectedTypeFilter] = useState<string>('all');
  const [selectedCategoryFilter, setSelectedCategoryFilter] = useState<string>('all');
  const [selectedSubcategoryFilter, setSelectedSubcategoryFilter] = useState<string>('all');
  const [sortConfig, setSortConfig] = useState<{ field: SortField; order: SortOrder }>({
    field: 'date',
    order: 'desc'
  });
  const [dateRange, setDateRange] = useState({
    start: (() => {
      const date = new Date();
      date.setHours(0, 0, 0, 0);
      date.setDate(1); // First day of current month
      return date;
    })(),
    end: (() => {
      const date = new Date();
      date.setHours(23, 59, 59, 999);
      date.setMonth(date.getMonth() + 1, 0); // Last day of current month
      return date;
    })()
  });
  
  useEffect(() => {
    if (currentOutlet) {
      fetchExpenses(currentOutlet, dateRange.start, dateRange.end);
      fetchTypes(currentOutlet);
      fetchCategories(currentOutlet);
    }
  }, [currentOutlet, dateRange, fetchExpenses, fetchCategories, fetchTypes]);
  
  const filteredAndSortedExpenses = React.useMemo(() => {
    return expenses
      .filter(expense => {
        const typeMatch = selectedTypeFilter === 'all' || expense.type?.id === selectedTypeFilter;
        const categoryMatch = selectedCategoryFilter === 'all' || expense.categoryId === selectedCategoryFilter;
        const subcategoryMatch = selectedSubcategoryFilter === 'all' || expense.subcategoryId === selectedSubcategoryFilter;
        return typeMatch && categoryMatch && subcategoryMatch;
      })
      .sort((a, b) => {
        const { field, order } = sortConfig;
        const multiplier = order === 'desc' ? -1 : 1;

        switch (field) {
          case 'date':
            return multiplier * (new Date(b.date).getTime() - new Date(a.date).getTime());
          case 'category':
            return multiplier * (a.category?.name || '').localeCompare(b.category?.name || '');
          case 'subcategory':
            return multiplier * ((a.subcategory?.name || '') || '').localeCompare((b.subcategory?.name || '') || '');
          case 'amount':
            return multiplier * (a.amount - b.amount);
          default:
            return 0;
        }
      });
  }, [expenses, selectedTypeFilter, selectedCategoryFilter, selectedSubcategoryFilter, sortConfig]);

  // Calculate totals by unit
  const quantityTotals = React.useMemo(() => {
    const totals: Record<string, { quantity: number; amount: number }> = {};
    
    filteredAndSortedExpenses.forEach(expense => {
      if (expense.quantity && expense.subcategory?.unit) {
        const unit = expense.subcategory.unit;
        if (!totals[unit]) {
          totals[unit] = { quantity: 0, amount: 0 };
        }
        totals[unit].quantity += Number(expense.quantity);
        totals[unit].amount += Number(expense.amount);
      }
    });
    
    return totals;
  }, [filteredAndSortedExpenses]);

  const handleDateChange = (type: 'start' | 'end', date: string) => {
    const newDate = new Date(date);
    if (type === 'end') {
      newDate.setHours(23, 59, 59, 999);
    } else {
      newDate.setHours(0, 0, 0, 0);
    }
    setDateRange(prev => ({
      ...prev,
      [type]: newDate
    }));
  };

  const handleExport = () => {
    // TODO: Implement CSV export
    console.log('Export functionality to be implemented');
  };

  const SortIcon = ({ field }: { field: SortField }) => {
    if (sortConfig.field !== field) return null;
    return sortConfig.order === 'desc' ? 
      <ArrowDown className="h-4 w-4 ml-1" /> : 
      <ArrowUp className="h-4 w-4 ml-1" />;
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-4">
          <Link
            to="/expenses"
            className="text-gray-600 hover:text-gray-900 flex items-center"
          >
            <ArrowLeft className="h-5 w-5 mr-1" />
            Back to Expenses
          </Link>
          <h1 className="text-2xl font-semibold text-gray-900">Expense Transactions</h1>
        </div>
        <button
          onClick={handleExport}
          className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
        >
          <Download className="h-5 w-5 mr-2" />
          Export
        </button>
      </div>

      {/* Filters */}
      <div className="bg-white p-4 rounded-lg shadow">
        <div className="flex items-center space-x-4">
          <Filter className="h-5 w-5 text-gray-400" />
          <div className="flex space-x-4">
            <div className="w-48">
              <label className="block text-sm font-medium text-gray-700">Type</label>
              <select
                value={selectedTypeFilter}
                onChange={(e) => {
                  setSelectedTypeFilter(e.target.value);
                  setSelectedCategoryFilter('all'); // Reset category when type changes
                  setSelectedSubcategoryFilter('all'); // Reset subcategory when type changes
                }}
                className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              >
                <option value="all">All Types</option>
                {types.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Start Date</label>
              <input
                type="date"
                value={format(dateRange.start, 'yyyy-MM-dd')} 
                onChange={(e) => handleDateChange('start', e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">End Date</label>
              <input
                type="date"
                value={format(dateRange.end, 'yyyy-MM-dd')}
                onChange={(e) => handleDateChange('end', e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
            </div>
            <div className="w-48">
              <label className="block text-sm font-medium text-gray-700">Category</label>
              <select
                value={selectedCategoryFilter}
                onChange={(e) => {
                  setSelectedCategoryFilter(e.target.value);
                  setSelectedSubcategoryFilter('all'); // Reset subcategory when category changes
                }}
                className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              >
                <option value="all">All Categories</option>
                {categories
                  .filter(c => selectedTypeFilter === 'all' || c.typeId === selectedTypeFilter)
                  .map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="w-48">
              <label className="block text-sm font-medium text-gray-700">Subcategory</label>
              <select
                value={selectedSubcategoryFilter}
                onChange={(e) => setSelectedSubcategoryFilter(e.target.value)}
                className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              >
                <option value="all">All Subcategories</option>
                {categories
                  .find(c => c.id === selectedCategoryFilter)
                  ?.subcategories?.map((subcategory) => (
                    <option key={subcategory.id} value={subcategory.id}>
                      {subcategory.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className="space-y-4">
        {/* Quantity Totals */}
        {Object.keys(quantityTotals).length > 0 && (
          <div className="bg-white p-4 rounded-lg shadow">
            <h3 className="text-sm font-medium text-gray-700 mb-3">Quantity Totals</h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {Object.entries(quantityTotals).map(([unit, data]) => (
                <div key={unit} className="bg-gray-50 p-3 rounded-lg">
                  <div className="text-sm font-medium text-gray-900">
                    {data.quantity.toLocaleString()} {unit}
                  </div>
                  <div className="text-xs text-gray-500">
                    Total: {formatCurrency(data.amount)}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Transactions Table */}
        <div className="bg-white shadow rounded-lg overflow-hidden">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                  onClick={() => setSortConfig(prev => ({
                    field: 'date',
                    order: prev.field === 'date' && prev.order === 'desc' ? 'asc' : 'desc'
                  }))}
                >
                  <div className="flex items-center">
                    Date & Time
                    <SortIcon field="date" />
                  </div>
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Type
                </th>
                <th 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                  onClick={() => setSortConfig(prev => ({
                    field: 'category',
                    order: prev.field === 'category' && prev.order === 'desc' ? 'asc' : 'desc'
                  }))}
                >
                  <div className="flex items-center">
                    Category
                    <SortIcon field="category" />
                  </div>
                </th>
                <th 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                  onClick={() => setSortConfig(prev => ({
                    field: 'subcategory',
                    order: prev.field === 'subcategory' && prev.order === 'desc' ? 'asc' : 'desc'
                  }))}
                >
                  <div className="flex items-center">
                    Subcategory
                    <SortIcon field="subcategory" />
                  </div>
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Description
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Quantity
                </th>
                <th 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                  onClick={() => setSortConfig(prev => ({
                    field: 'amount',
                    order: prev.field === 'amount' && prev.order === 'desc' ? 'asc' : 'desc'
                  }))}
                >
                  <div className="flex items-center">
                    Amount
                    <SortIcon field="amount" />
                  </div>
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Payment Method
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Created By
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredAndSortedExpenses.map((expense) => (
                <tr key={expense.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {format(new Date(expense.createdAt), 'PPp')}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {expense.type?.name || '-'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm font-medium text-gray-900">
                      {expense.category?.name}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm font-medium text-gray-900">
                      {expense.subcategory?.name || '-'}
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="text-sm text-gray-900">{expense.description || '-'}</div>
                    {expense.referenceNumber && (
                      <div className="text-xs text-gray-500">
                        Ref: {expense.referenceNumber}
                      </div>
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {expense.quantity > 0 && (
                      <span>
                        {expense.quantity} {expense.subcategory?.unit || 'units'}
                      </span>
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm font-medium text-gray-900">
                      {formatCurrency(expense.amount)}
                    </div>
                    {expense.taxAmount > 0 && (
                      <div className="text-xs text-gray-500">
                        Tax: {formatCurrency(expense.taxAmount)}
                      </div>
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className="text-sm text-gray-900 capitalize">
                      {expense.paymentMethod.replace('_', ' ')}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {expense.createdByName}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}