import React from 'react';
import { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { useExpenseStore } from '../../store/useExpenseStore'; 
import { ExpenseCategory, ExpenseType } from '../../types/expense';
import toast from 'react-hot-toast';

interface ExpenseCategoryModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedTypeId?: string;
  types: ExpenseType[];
  category?: ExpenseCategory;
  outletId: string;
}

export function ExpenseCategoryModal({ isOpen, onClose, selectedTypeId, types, category, outletId }: ExpenseCategoryModalProps) {
  const { addCategory, updateCategory } = useExpenseStore();
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    typeId: selectedTypeId || '',
    isActive: true
  });

  useEffect(() => {
    if (category) {
      setFormData({
        name: category.name,
        description: category.description || '',
        typeId: category.typeId,
        isActive: category.isActive
      });
    } else {
      setFormData({
        name: '',
        description: '',
        typeId: selectedTypeId || '',
        isActive: true
      });
    }
  }, [category, selectedTypeId]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (!formData.typeId) {
        toast.error('Please select a type');
        return;
      }

      if (category) {
        if (!category.id) {
          throw new Error('Invalid category ID');
        }
        await updateCategory(category.id, {
          name: formData.name,
          description: formData.description,
          typeId: formData.typeId
        });
      } else {
        await addCategory(outletId, formData.typeId, formData.name, formData.description);
      }
      onClose();
    } catch (error) {
      console.error('Error saving category:', error);
      toast.error('Failed to save category');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">
            {category ? 'Edit Category' : 'Add Category'}
          </h2>
          <div className="text-sm text-gray-500">
            Type: {types.find(t => t.id === formData.typeId)?.name}
          </div>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="h-6 w-6" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Type</label>
            <select
              value={formData.typeId}
              onChange={(e) => setFormData(prev => ({ ...prev, typeId: e.target.value }))}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              required
            >
              <option value="">Select Type</option>
              {types.map((type) => (
                <option key={type.id} value={type.id}>
                  {type.name}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Name</label>
            <input
              type="text"
              value={formData.name}
              onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Description</label>
            <textarea
              value={formData.description}
              onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
              rows={3}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>

          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
            >
              {category ? 'Update' : 'Add'} Category
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}