import React from 'react';
import { ComposedChart, Line, Bar, ResponsiveContainer, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import { format } from 'date-fns';
import { formatCurrency } from '../../lib/utils';
import { ProfitData } from '../../types/reports';
import { TrendingUp, DollarSign, Percent } from 'lucide-react';

interface ProfitComparisonReportProps {
  data: ProfitData[];
  dateRange: string;
}

const CHART_COLORS = {
  sales: '#4ADE80',     // Green-400
  expenses: '#F87171',  // Red-400
  profit: '#818CF8',    // Indigo-400
  profitLine: '#FBBF24' // Amber-400
};

export function ProfitComparisonReport({ data, dateRange }: ProfitComparisonReportProps) {
  if (!data || data.length === 0) {
    return (
      <div className="text-center py-8 text-gray-500">
        No profit data available for the selected date range
      </div>
    );
  }

  const totalProfit = data.reduce((sum, day) => sum + day.netProfit, 0);
  const averageProfit = data.length > 0 ? totalProfit / data.length : 0;
  const profitMargin = data.reduce((sum, day) => sum + day.totalSales, 0) > 0 
    ? (totalProfit / data.reduce((sum, day) => sum + day.totalSales, 0)) * 100 
    : 0;

  return (
    <div className="space-y-6">
      <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-200">
        <h3 className="text-lg font-medium text-gray-900 mb-6 flex items-center gap-2">
          <TrendingUp className="h-5 w-5 text-indigo-500" />
          Daily Profit Analysis
        </h3>
        <div className="h-[400px]">
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart 
              data={data} 
              margin={{ top: 10, right: 30, left: 20, bottom: 60 }}
            >
              <XAxis 
                dataKey="date"
                interval={0}
                angle={-30}
                textAnchor="end"
                height={60}
                fontSize={11}
                tickFormatter={(date) => format(new Date(date), dateRange === 'today' ? 'HH:mm' : 'MMM dd')}
              />
              <YAxis 
                tickFormatter={(value) => `₹${Math.abs(value) >= 1000 ? `${(value/1000).toFixed(1)}K` : value}`}
                fontSize={11}
              />
              <Tooltip 
                formatter={(value: number) => formatCurrency(value)}
                labelFormatter={(label) => format(new Date(label), 'PPP')}
                contentStyle={{ 
                  fontSize: '12px',
                  backgroundColor: 'rgba(255, 255, 255, 0.95)',
                  borderRadius: '8px',
                  border: '1px solid #e5e7eb',
                  boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)'
                }}
              />
              <Legend 
                verticalAlign="top"
                height={36}
                iconType="circle"
              />
              <Bar 
                dataKey="totalSales" 
                name="Sales" 
                fill={CHART_COLORS.sales} 
                radius={[4, 4, 0, 0]}
                barSize={24}
                fillOpacity={0.9}
                stackId="stack"
              />
              <Bar 
                dataKey="totalExpenses" 
                name="Expenses" 
                fill={CHART_COLORS.expenses}
                radius={[4, 4, 0, 0]}
                barSize={24}
                fillOpacity={0.9}
                stackId="stack"
              />
              <Bar 
                dataKey="netProfit" 
                name="Net Profit" 
                fill={CHART_COLORS.profit}
                radius={[4, 4, 0, 0]}
                barSize={24}
                fillOpacity={0.9}
                stackId="stack"
              />
              <Line
                type="monotone"
                dataKey="netProfit"
                name="Net Profit Trend"
                stroke={CHART_COLORS.profitLine}
                strokeWidth={2}
                dot={false}
                activeDot={{ r: 4, strokeWidth: 2 }}
                strokeDasharray="5 5"
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}