import React, { useState, useEffect } from 'react';
import { ChevronRight, ChevronDown, Plus, Edit2, Trash2 } from 'lucide-react';
import { ExpenseType, ExpenseCategory, ExpenseSubcategory } from '../../types/expense';
import { cn } from '../../lib/utils';

interface ExpenseTypeTreeProps {
  types: ExpenseType[];
  categories: ExpenseCategory[];
  onEditType: (type: ExpenseType) => void;
  onEditCategory: (category: ExpenseCategory) => void;
  onEditSubcategory: (subcategory: ExpenseSubcategory, categoryId: string) => void;
  onAddCategory: (typeId: string) => void;
  onAddSubcategory: (categoryId: string) => void;
  onDeleteType: (type: ExpenseType) => void;
  onDeleteCategory: (category: ExpenseCategory) => void;
  onDeleteSubcategory: (subcategory: ExpenseSubcategory, categoryId: string) => void;
}

export function ExpenseTypeTree({
  types,
  categories,
  onEditType,
  onEditCategory,
  onEditSubcategory,
  onAddCategory,
  onAddSubcategory,
  onDeleteType,
  onDeleteCategory,
  onDeleteSubcategory,
}: ExpenseTypeTreeProps) {
  const [expandedTypes, setExpandedTypes] = useState<Set<string>>(new Set());
  const [expandedCategories, setExpandedCategories] = useState<Set<string>>(new Set());

  // Initialize expanded state
  useEffect(() => {
    // Expand all types by default
    setExpandedTypes(new Set(types.map(type => type.id)));
  }, [types]);

  // Organize categories by type
  const categoriesByType = React.useMemo(() => {
    const map = new Map<string, ExpenseCategory[]>();
    categories.forEach(category => {
      if (!category.typeId) return; // Skip categories without type
      if (!map.has(category.typeId)) {
        map.set(category.typeId, []);
      }
      map.get(category.typeId)?.push(category);
    });
    return map;
  }, [categories, types]);

  const toggleType = (typeId: string) => {
    const newExpanded = new Set(expandedTypes);
    if (newExpanded.has(typeId)) {
      newExpanded.delete(typeId);
    } else {
      newExpanded.add(typeId);
    }
    setExpandedTypes(newExpanded);
  };

  const toggleCategory = (categoryId: string) => {
    const newExpanded = new Set(expandedCategories);
    if (newExpanded.has(categoryId)) {
      newExpanded.delete(categoryId);
    } else {
      newExpanded.add(categoryId);
    }
    setExpandedCategories(newExpanded);
  };

  if (!types.length) {
    return (
      <div className="bg-white rounded-lg shadow-sm p-8 text-center">
        <p className="text-gray-500">No expense types found. Add a type to get started.</p>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-sm">
      <div className="p-4">
        <div className="space-y-2">
          {types.map((type) => {
            const typeCategories = categoriesByType.get(type.id) || [];
            const isTypeExpanded = expandedTypes.has(type.id);

            return (
              <div key={type.id} className="border rounded-lg">
                <div className="flex items-center justify-between p-3 bg-gray-50 rounded-t-lg">
                  <div className="flex items-center">
                    <button
                      onClick={() => toggleType(type.id)}
                      className="p-1 hover:bg-gray-200 rounded-full mr-2"
                    >
                      {isTypeExpanded ? (
                        <ChevronDown className="h-4 w-4 text-gray-500" />
                      ) : (
                        <ChevronRight className="h-4 w-4 text-gray-500" />
                      )}
                    </button>
                    <div>
                      <span className="font-medium">{type.name}</span>
                      {type.description && (
                        <p className="text-sm text-gray-500">{type.description}</p>
                      )}
                    </div>
                  </div>
                  <div className="flex items-center space-x-2">
                    <button
                      onClick={() => onAddCategory(type.id)}
                      className="p-1 hover:bg-gray-200 rounded-full text-indigo-600"
                      title="Add Category"
                    >
                      <Plus className="h-4 w-4" />
                    </button>
                    <button
                      onClick={() => onEditType(type)}
                      className="p-1 hover:bg-gray-200 rounded-full text-indigo-600"
                      title="Edit Type"
                    >
                      <Edit2 className="h-4 w-4" />
                    </button>
                    <button
                      onClick={() => onDeleteType(type)}
                      className="p-1 hover:bg-gray-200 rounded-full text-red-600"
                      title="Delete Type"
                    >
                      <Trash2 className="h-4 w-4" />
                    </button>
                  </div>
                </div>

                {isTypeExpanded && (
                  <div className="p-2">
                    <div className="space-y-2 pl-6">
                      {typeCategories.map((category) => {
                        const isCategoryExpanded = expandedCategories.has(category.id);

                        return (
                          <div key={category.id} className="border rounded-lg">
                            <div className="flex items-center justify-between p-2 bg-gray-50">
                              <div className="flex items-center">
                                <button
                                  onClick={() => toggleCategory(category.id)}
                                  className="p-1 hover:bg-gray-200 rounded-full mr-2"
                                >
                                  {isCategoryExpanded ? (
                                    <ChevronDown className="h-4 w-4 text-gray-500" />
                                  ) : (
                                    <ChevronRight className="h-4 w-4 text-gray-500" />
                                  )}
                                </button>
                                <div>
                                  <span>{category.name}</span>
                                  {category.description && (
                                    <p className="text-sm text-gray-500">{category.description}</p>
                                  )}
                                </div>
                              </div>
                              <div className="flex items-center space-x-2">
                                <button
                                  onClick={() => onAddSubcategory(category.id)}
                                  className="p-1 hover:bg-gray-200 rounded-full text-indigo-600"
                                  title="Add Subcategory"
                                >
                                  <Plus className="h-4 w-4" />
                                </button>
                                <button
                                  onClick={() => onEditCategory(category)}
                                  className="p-1 hover:bg-gray-200 rounded-full text-indigo-600"
                                  title="Edit Category"
                                >
                                  <Edit2 className="h-4 w-4" />
                                </button>
                                <button
                                  onClick={() => onDeleteCategory(category)}
                                  className="p-1 hover:bg-gray-200 rounded-full text-red-600"
                                  title="Delete Category"
                                >
                                  <Trash2 className="h-4 w-4" />
                                </button>
                              </div>
                            </div>

                            {isCategoryExpanded && category.subcategories && (
                              <div className="p-2">
                                <div className="space-y-1 pl-6">
                                  {category.subcategories.map((subcategory) => (
                                    <div
                                      key={subcategory.id}
                                      className="flex items-center justify-between p-2 rounded-lg hover:bg-gray-50"
                                    >
                                      <div>
                                        <span className="text-sm">
                                          {subcategory.name}
                                          {(subcategory.unit || subcategory.quantity > 0) && (
                                            <span className="ml-2 text-xs text-gray-500">
                                              ({subcategory.quantity} {subcategory.unit})
                                            </span>
                                          )}
                                        </span>
                                        {subcategory.description && (
                                          <p className="text-xs text-gray-500">{subcategory.description}</p>
                                        )}
                                      </div>
                                      <div className="flex items-center space-x-2">
                                        <button
                                          onClick={() => onEditSubcategory(subcategory, category.id)}
                                          className="p-1 hover:bg-gray-200 rounded-full text-indigo-600"
                                          title="Edit Subcategory"
                                        >
                                          <Edit2 className="h-4 w-4" />
                                        </button>
                                        <button
                                          onClick={() => onDeleteSubcategory(subcategory, category.id)}
                                          className="p-1 hover:bg-gray-200 rounded-full text-red-600"
                                          title="Delete Subcategory"
                                        >
                                          <Trash2 className="h-4 w-4" />
                                        </button>
                                      </div>
                                    </div>
                                  ))}
                                  {(!category.subcategories || category.subcategories.length === 0) && (
                                    <div className="text-sm text-gray-500 py-2">
                                      No subcategories found
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      })}
                      {typeCategories.length === 0 && (
                        <div className="text-sm text-gray-500 py-2">
                          No categories found
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}