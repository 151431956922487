import React from 'react';
import { PieChart, Pie, ResponsiveContainer, Tooltip, Cell, Legend } from 'recharts';
import { formatCurrency } from '../../lib/utils';

interface ExpenseTypeDistributionProps {
  expenses: any[];
  types: any[];
}

const COLORS = ['#FF8C00', '#4CAF50', '#2196F3', '#9C27B0', '#F44336'];

export function ExpenseTypeDistribution({ expenses, types }: ExpenseTypeDistributionProps) {
  // Calculate total amount first
  const totalAmount = expenses.reduce((sum, expense) => sum + expense.amount, 0);

  // Calculate and sort type data by percentage
  const typeData = types
    .map(type => {
      const typeExpenses = expenses.filter(expense => 
        expense.type?.id === type.id
      );
      
      const amount = typeExpenses.reduce((sum, expense) => 
        sum + expense.amount, 0
      );

      const percentage = (amount / totalAmount) * 100;

      return {
        name: type.name,
        value: amount,
        percentage
      };
    })
    .filter(type => type.value > 0)
    .sort((a, b) => b.percentage - a.percentage);

  // Format data for chart
  const chartData = typeData.map(type => ({
    ...type,
    percentage: type.percentage.toFixed(1)
  }));

  if (chartData.length === 0) {
    return (
      <div className="text-center py-8 text-gray-500">
        No expense data available for the selected period
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <h2 className="text-lg font-semibold mb-4">Expense Type Distribution</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Pie Chart */}
        <div className="h-[300px]">
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={chartData}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={({ name, percent }) => `${name} (${(percent * 100).toFixed(1)}%)`}
                outerRadius={120}
                fill="#8884d8"
                dataKey="value"
              >
                {chartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip 
                formatter={(value: number) => formatCurrency(value)}
                contentStyle={{
                  backgroundColor: 'rgba(255, 255, 255, 0.95)',
                  borderRadius: '8px',
                  border: '1px solid #e5e7eb',
                  boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)'
                }}
              />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>

        {/* Amount Details */}
        <div className="flex flex-col justify-center">
          <div className="space-y-4">
            {chartData.map((type, index) => (
              <div key={type.name} className="flex items-center justify-between">
                <div className="flex items-center">
                  <div 
                    className="w-3 h-3 rounded-full mr-2" 
                    style={{ backgroundColor: COLORS[index % COLORS.length] }}
                  />
                  <span className="font-medium">{type.name}</span>
                </div>
                <div className="text-right">
                  <div className="font-medium">{formatCurrency(type.value)}</div>
                  <div className="text-sm text-gray-500">{type.percentage}%</div>
                </div>
              </div>
            ))}
            <div className="pt-4 border-t border-gray-200">
              <div className="flex justify-between items-center font-semibold">
                <span>Total</span>
                <span>{formatCurrency(totalAmount)}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}