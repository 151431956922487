import { supabase } from '../supabase-client';
import { ProfitData } from '../../types/reports';
import toast from 'react-hot-toast';

export async function fetchDailyProfitComparison(
  outletId: string, 
  startDate: Date, 
  endDate: Date
): Promise<ProfitData[]> {
  try {
    if (!outletId) {
      return [];
    }

    const { data, error } = await supabase
      .from('daily_profit_comparison')
      .select('*')
      .eq('outlet_id', outletId)
      .gte('date', startDate.toISOString().split('T')[0])
      .lte('date', endDate.toISOString().split('T')[0])
      .order('date', { ascending: true });

    if (error) {
      console.error('Error fetching profit data:', error);
      return [];
    }

    return (data || []).map(row => ({
      date: row.date,
      totalSales: Number(row.total_sales),
      totalExpenses: Number(row.total_expenses),
      netProfit: Number(row.net_profit),
      orderCount: Number(row.order_count),
      expenseCount: Number(row.expense_count),
      totalGst: Number(row.total_gst),
      totalPackageCharge: Number(row.total_package_charge),
      totalDiscounts: Number(row.total_discounts)
    }));
  } catch (error) {
    console.error('Error fetching profit comparison:', error);
    return [];
  }
}