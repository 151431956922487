import React from 'react';
import { Edit2, Trash2, Plus } from 'lucide-react';
import { ExpenseCategory, ExpenseSubcategory } from '../../types/expense';
import { ExpenseSubcategoryList } from './ExpenseSubcategoryList';
import { useExpenseStore } from '../../store/useExpenseStore';
import toast from 'react-hot-toast';

interface ExpenseCategoryListProps {
  categories: ExpenseCategory[];
  onEdit: (category: ExpenseCategory) => void;
  onEditSubcategory: (subcategory: ExpenseSubcategory, categoryId: string) => void;
  onAddSubcategory: (categoryId: string) => void;
}

export function ExpenseCategoryList({ 
  categories, 
  onEdit, 
  onEditSubcategory,
  onAddSubcategory 
}: ExpenseCategoryListProps) {
  const { updateCategory, deleteCategory } = useExpenseStore();

  const handleToggleActive = async (category: ExpenseCategory) => {
    try {
      await updateCategory(category.id, { isActive: !category.isActive });
      toast.success(`Category ${category.isActive ? 'disabled' : 'enabled'} successfully`);
    } catch (error) {
      console.error('Error toggling category status:', error);
      toast.error('Failed to update category status');
    }
  };

  const handleDelete = async (category: ExpenseCategory) => {
    if (!window.confirm('Are you sure you want to delete this category? This will also delete all subcategories.')) {
      return;
    }
    try {
      await deleteCategory(category.id);
      toast.success('Category deleted successfully');
    } catch (error) {
      console.error('Error deleting category:', error);
      toast.error('Failed to delete category');
    }
  };

  return (
    <div className="bg-white shadow rounded-lg overflow-hidden">
      <div className="divide-y divide-gray-200">
        {categories.map((category) => (
          <div key={category.id} className="hover:bg-gray-50">
            <div className="px-6 py-4 flex items-center justify-between">
              <div className="flex-1 min-w-0">
                <div className="flex items-center justify-between">
                  <div>
                    <h3 className="text-sm font-medium text-gray-900">{category.name}</h3>
                    {category.description && (
                      <p className="text-sm text-gray-500">{category.description}</p>
                    )}
                  </div>
                  <div className="flex items-center space-x-4">
                    <button
                      onClick={() => handleToggleActive(category)}
                      className={`px-2 py-1 text-xs font-semibold rounded-full ${
                        category.isActive
                          ? 'bg-green-100 text-green-800'
                          : 'bg-red-100 text-red-800'
                      }`}
                    >
                      {category.isActive ? 'Active' : 'Inactive'}
                    </button>
                    <button
                      onClick={() => onEdit(category)}
                      className="text-indigo-600 hover:text-indigo-900"
                    >
                      <Edit2 className="h-4 w-4" />
                    </button>
                    <button
                      onClick={() => handleDelete(category)}
                      className="text-red-600 hover:text-red-900"
                    >
                      <Trash2 className="h-4 w-4" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="px-6 pb-4 border-t border-gray-100">
              <ExpenseSubcategoryList
                category={category}
                onEdit={(subcategory) => onEditSubcategory(subcategory, category.id)}
                onAdd={() => onAddSubcategory(category.id)}
              />
            </div>
          </div>
        ))}
        {categories.length === 0 && (
          <div className="p-6 text-center text-gray-500">
            <p>No categories found. Add a category to get started.</p>
          </div>
        )}
      </div>
    </div>
  );
}