import React from 'react';
import { format } from 'date-fns';
import { Edit2, Trash2, ArrowUp, ArrowDown } from 'lucide-react';
import { DailyExpense } from '../../types/expense';
import { formatCurrency } from '../../lib/utils';
import { useDailyExpenseStore } from '../../store/useDailyExpenseStore';
import toast from 'react-hot-toast';

type SortField = 'date' | 'amount';
type SortOrder = 'asc' | 'desc';

interface DailyExpenseListProps {
  expenses: DailyExpense[];
  onEdit: (expense: DailyExpense) => void;
  sortConfig: { field: SortField; order: SortOrder };
  onSort: (field: SortField) => void;
}

export function DailyExpenseList({ expenses, onEdit, sortConfig, onSort }: DailyExpenseListProps) {
  const { deleteExpense } = useDailyExpenseStore();

  const handleDelete = async (expense: DailyExpense) => {
    if (window.confirm('Are you sure you want to delete this expense?')) {
      try {
        await deleteExpense(expense.id);
        toast.success('Daily expense deleted successfully');
      } catch (error) {
        console.error('Error deleting daily expense:', error);
        toast.error('Failed to delete daily expense');
      }
    }
  };

  const SortIcon = ({ field }: { field: SortField }) => {
    if (sortConfig.field !== field) return null;
    return sortConfig.order === 'desc' ? 
      <ArrowDown className="h-4 w-4 ml-1" /> : 
      <ArrowUp className="h-4 w-4 ml-1" />;
  };

  return (
    <div className="bg-white shadow-sm rounded-xl overflow-hidden border border-gray-100">
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gradient-to-r from-gray-50 to-white border-b border-gray-200">
            <tr>
              <th 
                className="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                onClick={() => onSort('date')}
              >
                <div className="flex items-center">
                  Date
                  <SortIcon field="date" />
                </div>
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Description
              </th>
              <th 
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                onClick={() => onSort('amount')}
              >
                <div className="flex items-center">
                  Amount
                  <SortIcon field="amount" />
                </div>
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Added By
              </th>
              <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {expenses.map((expense) => (
              <tr key={expense.id} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {format(new Date(expense.date), 'dd/MM/yyyy')}
                </td>
                <td className="px-6 py-4 text-sm text-gray-900">
                  {expense.description || '-'}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {formatCurrency(expense.amount)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {expense.createdByName}
                  <div className="text-xs text-gray-500">
                    {format(new Date(expense.createdAt), 'dd/MM/yyyy HH:mm')}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <button
                    onClick={() => onEdit(expense)}
                    className="text-indigo-600 hover:text-indigo-900 mr-3"
                  >
                    <Edit2 className="h-4 w-4" />
                  </button>
                  <button
                    onClick={() => handleDelete(expense)}
                    className="text-red-600 hover:text-red-900"
                  >
                    <Trash2 className="h-4 w-4" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      
      {expenses.length === 0 && (
        <div className="text-center py-8">
          <p className="text-gray-500">No daily expenses found</p>
        </div>
      )}
    </div>
  );
}