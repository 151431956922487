import { create } from 'zustand';
import { DailyExpense } from '../types/expense';
import {
  fetchDailyExpenses,
  createDailyExpense,
  updateDailyExpense,
  deleteDailyExpense
} from '../lib/api/daily-expenses';

interface DailyExpenseStore {
  expenses: DailyExpense[];
  isLoading: boolean;
  error: Error | null;
  fetchExpenses: (outletId: string, startDate?: Date, endDate?: Date) => Promise<void>;
  addExpense: (expense: Omit<DailyExpense, 'id' | 'createdAt' | 'updatedAt' | 'createdBy' | 'updatedBy' | 'createdByName' | 'updatedByName'>) => Promise<void>;
  updateExpense: (id: string, updates: Partial<Omit<DailyExpense, 'id' | 'createdAt' | 'updatedAt' | 'createdBy' | 'updatedBy' | 'createdByName' | 'updatedByName'>>) => Promise<void>;
  deleteExpense: (id: string) => Promise<void>;
}

export const useDailyExpenseStore = create<DailyExpenseStore>((set) => ({
  expenses: [],
  isLoading: false,
  error: null,

  fetchExpenses: async (outletId: string, startDate?: Date, endDate?: Date) => {
    try {
      set({ isLoading: true, error: null });
      const data = await fetchDailyExpenses(outletId, startDate, endDate);
      set({ expenses: data, isLoading: false });
    } catch (error) {
      set({ error: error as Error, isLoading: false });
    }
  },

  addExpense: async (expense) => {
    try {
      set({ isLoading: true, error: null });
      const data = await createDailyExpense(expense);
      set((state) => ({
        expenses: [data, ...state.expenses],
        isLoading: false,
      }));
    } catch (error) {
      set({ error: error as Error, isLoading: false });
      throw error;
    }
  },

  updateExpense: async (id, updates) => {
    try {
      set({ isLoading: true, error: null });
      const data = await updateDailyExpense(id, updates);
      set((state) => ({
        expenses: state.expenses.map((exp) =>
          exp.id === id ? { ...exp, ...data } : exp
        ),
        isLoading: false,
      }));
    } catch (error) {
      set({ error: error as Error, isLoading: false });
      throw error;
    }
  },

  deleteExpense: async (id) => {
    try {
      set({ isLoading: true, error: null });
      await deleteDailyExpense(id);
      set((state) => ({
        expenses: state.expenses.filter((exp) => exp.id !== id),
        isLoading: false,
      }));
    } catch (error) {
      set({ error: error as Error, isLoading: false });
      throw error;
    }
  },
}));