import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useAuthStore } from './store/useAuthStore';
import { Toaster } from 'react-hot-toast';
import { initializeSupabase } from './lib/supabase-client';
import { StoreHeader } from './components/store/StoreHeader';


// Layout Components
import { Header } from './components/layout/Header'; 

// Auth Components
import { LoginForm } from './components/auth/LoginForm';
import { RegisterForm } from './components/auth/RegisterForm';
import { PrivateRoute } from './components/auth/PrivateRoute';

// Admin Pages
import { Dashboard } from './pages/Dashboard';
import { POS } from './pages/POS';
import { MenuManagement } from './pages/MenuManagement';
import { CategoryManagement } from './pages/CategoryManagement';
import { Orders } from './pages/Orders';
import { Reports } from './pages/Reports';
import { Settings } from './pages/Settings';
import { DailyExpenses } from './pages/DailyExpenses';
import { ExpenseManagement } from './pages/ExpenseManagement';
import { InventoryManagement } from './pages/InventoryManagement';
import { ExpenseTransactionList } from './pages/expense/ExpenseTransactionList';
import { TransactionList } from './pages/inventory/TransactionList';
import { CustomerOrdersManagement } from './pages/CustomerOrdersManagement';

// Store Pages
import { Checkout } from './pages/store/Checkout';
import { StoreLanding } from './pages/store/StoreLanding';
import { StoreMenu } from './pages/store/Menu';
import { CustomerOrders } from './pages/store/Orders';
import { CustomerProfile } from './pages/store/Profile';

function AppContent() {
  const { initialize, isLoading, user } = useAuthStore();
  const location = useLocation();

  useEffect(() => {
    // Initialize Supabase and auth
    initializeSupabase();
    initialize();
  }, [initialize]);

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  const isAdminOrStaff = user && ['admin', 'manager', 'staff'].includes(user.role);

  return (
      <div className="min-h-screen bg-gray-50">
        {/* Show appropriate header based on route and user role */}
        {!location.pathname.includes('/login') && 
         !location.pathname.includes('/register') && (
           isAdminOrStaff ? <Header /> : <StoreHeader />
         )}

        <div className={`${isAdminOrStaff ? 'admin-container' : ''}`}>
          <Routes>
            {/* Public Routes */}
            <Route path="/login" element={
              user ? (
                <Navigate to={isAdminOrStaff ? "/dashboard" : "/"} replace />
              ) : (
                <LoginForm />
              )
            } />
            <Route path="/register" element={
              user ? (
                <Navigate to={isAdminOrStaff ? "/dashboard" : "/"} replace />
              ) : (
                <RegisterForm />
              )
            } />

            {/* Customer Routes */}
            <Route path="/" element={<StoreLanding />} />
            <Route path="/menu" element={<StoreMenu />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route 
              path="/my-orders" 
              element={
                <PrivateRoute requiredRoles={['customer']}>
                  <CustomerOrders />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/profile" 
              element={
                <PrivateRoute requiredRoles={['customer']}>
                  <CustomerProfile />
                </PrivateRoute>
              } 
            />

            {/* Admin/Staff Routes */}
            <Route 
              path="/dashboard" 
              element={
                <PrivateRoute requiredRoles={['admin', 'manager', 'staff']}>
                  <Dashboard />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/pos" 
              element={
                <PrivateRoute requiredRoles={['admin', 'manager', 'staff']}>
                  <POS />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/management" 
              element={
                <PrivateRoute requiredRoles={['admin', 'manager']}>
                  <MenuManagement />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/management/categories" 
              element={
                <PrivateRoute requiredRoles={['admin', 'manager']}>
                  <CategoryManagement />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/expenses" 
              element={
                <PrivateRoute requiredRoles={['admin', 'manager']}>
                  <ExpenseManagement />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/daily-expenses"
              element={
                <PrivateRoute requiredRoles={['admin', 'manager']}>
                  <DailyExpenses />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/expenses/transactions" 
              element={
                <PrivateRoute requiredRoles={['admin', 'manager']}>
                  <ExpenseTransactionList />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/inventory" 
              element={
                <PrivateRoute requiredRoles={['admin', 'manager']}>
                  <InventoryManagement />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/inventory/transactions" 
              element={
                <PrivateRoute requiredRoles={['admin', 'manager']}>
                  <TransactionList />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/reports" 
              element={
                <PrivateRoute requiredRoles={['admin', 'manager']}>
                  <Reports />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/settings" 
              element={
                <PrivateRoute requiredRoles={['admin']}>
                  <Settings />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/orders" 
              element={
                <PrivateRoute requiredRoles={['admin', 'manager', 'staff']}>
                  <Orders />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/customer-orders" 
              element={
                <PrivateRoute requiredRoles={['admin', 'manager', 'staff']}>
                  <CustomerOrdersManagement />
                </PrivateRoute>
              } 
            />

            {/* Redirect based on user role */}
            <Route 
              path="*" 
              element={
                isAdminOrStaff 
                  ? <Navigate to="/dashboard" replace />
                  : <Navigate to="/" replace />
              } 
            />
          </Routes>
        </div>

        <Toaster position="top-right" />
      </div>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;