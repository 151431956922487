import React from 'react';
import { useExpenseStore } from '../../store/useExpenseStore';
import { DateRangeType } from '../../types';
import { formatCurrency } from '../../lib/utils';
import { PieChart, Pie, ResponsiveContainer, Tooltip, Cell, Legend } from 'recharts';

interface ExpenseReportProps {
  dateRange: DateRangeType;
  startDate: Date;
  endDate: Date;
  typeId: string;
}

const COLORS = ['#FF8C00', '#4CAF50', '#2196F3', '#9C27B0', '#F44336', '#FF5722', '#607D8B'];

export function ExpenseReport({ dateRange, startDate, endDate, typeId }: ExpenseReportProps) {
  const { expenses, categories } = useExpenseStore();

  // Filter expenses for this type
  const typeExpenses = expenses.filter(expense => {
    const expenseDate = new Date(expense.date);
    const category = categories.find(c => c.id === expense.categoryId);
    return expenseDate >= startDate && 
           expenseDate <= endDate && 
           category?.typeId === typeId;
  });

  // Calculate total amount first
  const totalAmount = typeExpenses.reduce((sum, expense) => sum + expense.amount, 0);

  // Calculate and sort category totals by percentage
  const categoryTotals = categories
    .filter(category => category.typeId === typeId)
    .map(category => {
      const categoryExpenses = typeExpenses.filter(e => e.categoryId === category.id);
      const categoryAmount = categoryExpenses.reduce((sum, e) => sum + e.amount, 0);
      const percentage = (categoryAmount / totalAmount) * 100;

      const subcategoryTotals = category.subcategories?.map(sub => {
        const subcategoryExpenses = categoryExpenses.filter(e => e.subcategoryId === sub.id);
        const subAmount = subcategoryExpenses.reduce((sum, e) => sum + e.amount, 0);
        const subPercentage = (subAmount / categoryAmount) * 100;

        return {
          name: sub.name,
          amount: subAmount,
          percentage: subPercentage,
          quantity: subcategoryExpenses.reduce((sum, e) => sum + (e.quantity || 0), 0),
          unit: sub.unit
        };
      }) || [];

      // Sort subcategories by percentage
      const sortedSubcategories = subcategoryTotals
        .filter(sub => sub.amount > 0)
        .sort((a, b) => b.percentage - a.percentage);

      return {
        name: category.name,
        amount: categoryAmount,
        percentage,
        subcategories: sortedSubcategories
      };
    })
    .filter(cat => cat.amount > 0)
    .sort((a, b) => b.percentage - a.percentage);

  // Prepare data for pie chart
  const pieChartData = categoryTotals.map(category => ({
    name: category.name,
    value: category.amount,
    percentage: category.percentage.toFixed(1)
  }));

  if (categoryTotals.length === 0) {
    return (
      <div className="text-center py-8 text-gray-500">
        No expenses found for this type in the selected date range
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Pie Chart */}
        <div className="bg-white p-6 rounded-lg shadow-sm">
          <h3 className="text-lg font-medium mb-4">Category Distribution</h3>
          <div className="h-[300px]">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={pieChartData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={({ name, percent }) => `${name} (${(percent * 100).toFixed(1)}%)`}
                  outerRadius={120}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {pieChartData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip 
                  formatter={(value: number) => formatCurrency(value)}
                  contentStyle={{
                    backgroundColor: 'rgba(255, 255, 255, 0.95)',
                    borderRadius: '8px',
                    border: '1px solid #e5e7eb',
                    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)'
                  }}
                />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Amount Details */}
        <div className="bg-white p-6 rounded-lg shadow-sm">
          <h3 className="text-lg font-medium mb-4">Amount Details</h3>
          <div className="space-y-4">
            {categoryTotals.map((category, index) => (
              <div key={category.name}>
                <div className="flex justify-between items-center mb-2">
                  <div className="flex items-center">
                    <div 
                      className="w-3 h-3 rounded-full mr-2" 
                      style={{ backgroundColor: COLORS[index % COLORS.length] }}
                    />
                    <span className="font-medium text-gray-900">{category.name}</span>
                  </div>
                  <div className="text-right">
                    <span className="font-medium text-gray-900">
                      {formatCurrency(category.amount)}
                    </span>
                    <span className="ml-2 text-sm text-gray-500">
                      ({category.percentage.toFixed(1)}%)
                    </span>
                  </div>
                </div>
              </div>
            ))}
            <div className="pt-4 mt-4 border-t border-gray-200">
              <div className="flex justify-between items-center">
                <span className="font-semibold text-gray-900">Total</span>
                <span className="font-semibold text-gray-900">
                  {formatCurrency(totalAmount)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Detailed Subcategory Breakdown */}
      <div className="bg-white p-6 rounded-lg shadow-sm">
        <h3 className="text-lg font-medium mb-4">Subcategory Breakdown</h3>
        <div className="space-y-6">
          {categoryTotals.map((category, categoryIndex) => (
            <div key={category.name} className="space-y-2">
              <div className="flex justify-between items-center">
                <div className="flex items-center">
                  <div 
                    className="w-3 h-3 rounded-full mr-2" 
                    style={{ backgroundColor: COLORS[categoryIndex % COLORS.length] }}
                  />
                  <h4 className="font-medium text-gray-900">{category.name}</h4>
                </div>
                <span className="text-sm text-gray-500">
                  {formatCurrency(category.amount)} ({category.percentage.toFixed(1)}%)
                </span>
              </div>
              <div className="pl-4 space-y-2">
                {category.subcategories.map((sub) => (
                  <div key={sub.name} className="flex justify-between items-center">
                    <div className="flex items-center">
                      <div className="w-2 h-2 rounded-full bg-indigo-500 mr-2"></div>
                      <span className="text-sm text-gray-600">
                        {sub.name}
                        {sub.quantity > 0 && sub.unit && (
                          <span className="text-gray-500 ml-2">
                            ({sub.quantity} {sub.unit})
                          </span>
                        )}
                      </span>
                    </div>
                    <div className="text-right">
                      <span className="text-sm font-medium text-gray-900">
                        {formatCurrency(sub.amount)}
                      </span>
                      <span className="text-xs text-gray-500 ml-2">
                        ({sub.percentage.toFixed(1)}%)
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}