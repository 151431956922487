import React, { useState, useEffect } from 'react';
import { useDailyExpenseStore } from '../store/useDailyExpenseStore';
import { useOutletStore } from '../store/useOutletStore';
import { format } from 'date-fns';
import { Plus, AlertCircle, Filter, Download, ArrowLeft, ArrowUp, ArrowDown } from 'lucide-react';
import { DailyExpenseModal } from '../components/expense/DailyExpenseModal';
import { DailyExpenseList } from '../components/expense/DailyExpenseList';
import { DailyExpenseSummary } from '../components/expense/DailyExpenseSummary';
import { Link } from 'react-router-dom';
import { formatCurrency } from '../lib/utils';
import { DailyExpense } from '../types/expense';
import toast from 'react-hot-toast';

type SortField = 'date' | 'amount';
type SortOrder = 'asc' | 'desc';

export function DailyExpenses() {
  const { expenses, fetchExpenses } = useDailyExpenseStore();
  const { currentOutlet } = useOutletStore();
  const [searchTerm, setSearchTerm] = useState('');
  const [showExpenseModal, setShowExpenseModal] = useState(false);
  const [selectedExpense, setSelectedExpense] = useState<DailyExpense | null>(null);
  const [sortConfig, setSortConfig] = useState<{ field: SortField; order: SortOrder }>({
    field: 'date',
    order: 'desc'
  });
  const [dateRange, setDateRange] = useState({
    start: (() => {
      const date = new Date();
      date.setHours(0, 0, 0, 0);
      return new Date(date.getFullYear(), date.getMonth(), 1);
    })(),
    end: (() => {
      const date = new Date();
      date.setHours(23, 59, 59, 999);
      return new Date(date.getFullYear(), date.getMonth() + 1, 0);
    })()
  });

  useEffect(() => {
    if (currentOutlet) {
      fetchExpenses(currentOutlet, dateRange.start, dateRange.end);
    }
  }, [currentOutlet, dateRange, fetchExpenses]);

  const handleDateChange = (type: 'start' | 'end', date: string) => {
    const newDate = new Date(date);
    if (type === 'end') {
      newDate.setHours(23, 59, 59, 999);
    } else {
      newDate.setHours(0, 0, 0, 0);
    }
    setDateRange(prev => ({
      ...prev,
      [type]: newDate
    }));
  };

  const filteredAndSortedExpenses = expenses
    .filter(expense => {
      const searchLower = searchTerm.toLowerCase();
      return expense.description?.toLowerCase().includes(searchLower) ||
             expense.createdByName?.toLowerCase().includes(searchLower);
    })
    .sort((a, b) => {
      const { field, order } = sortConfig;
      const multiplier = order === 'desc' ? -1 : 1;

      switch (field) {
        case 'date':
          return multiplier * (new Date(b.date).getTime() - new Date(a.date).getTime());
        case 'amount':
          return multiplier * (a.amount - b.amount);
        default:
          return 0;
      }
    });

  const totalAmount = filteredAndSortedExpenses.reduce((sum, exp) => sum + exp.amount, 0);

  if (!currentOutlet) {
    return (
      <div className="flex flex-col items-center justify-center h-[calc(100vh-4rem)]">
        <AlertCircle className="h-16 w-16 text-yellow-500 mb-4" />
        <h2 className="text-2xl font-semibold text-gray-900 mb-2">No Outlet Selected</h2>
        <p className="text-gray-600 mb-4">Please select an outlet to manage daily expenses</p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-4">
          <Link
            to="/dashboard"
            className="text-gray-600 hover:text-gray-900 flex items-center"
          >
            <ArrowLeft className="h-5 w-5 mr-1" />
            Back to Dashboard
          </Link>
          <h1 className="text-2xl font-semibold text-gray-900">Daily Expenses</h1>
        </div>
        <button
          onClick={() => {
            setSelectedExpense(null);
            setShowExpenseModal(true);
          }}
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-lg text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 transition-colors"
        >
          <Plus className="h-5 w-5 mr-2" />
          Add Daily Expense
        </button>
      </div>

      {/* Filters */}
      <div className="bg-white p-4 rounded-lg shadow">
        <div className="flex items-center space-x-4">
          <Filter className="h-5 w-5 text-gray-400" />
          <div className="flex space-x-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Start Date</label>
              <input
                type="date"
                value={format(dateRange.start, 'yyyy-MM-dd')}
                onChange={(e) => handleDateChange('start', e.target.value)}
                className="mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">End Date</label>
              <input
                type="date"
                value={format(dateRange.end, 'yyyy-MM-dd')}
                onChange={(e) => handleDateChange('end', e.target.value)}
                className="mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-4 gap-8">
        {/* Summary */}
        <div className="lg:col-span-1 order-2 lg:order-1">
          <DailyExpenseSummary
            expenses={filteredAndSortedExpenses}
            startDate={dateRange.start}
            endDate={dateRange.end}
          />
        </div>

        {/* Expense List */}
        <div className="lg:col-span-3 order-1 lg:order-2">
          <DailyExpenseList
            expenses={filteredAndSortedExpenses}
            onEdit={(expense) => {
              setSelectedExpense(expense);
              setShowExpenseModal(true);
            }}
            sortConfig={sortConfig}
            onSort={(field) => {
              setSortConfig(prev => ({
                field,
                order: prev.field === field && prev.order === 'desc' ? 'asc' : 'desc'
              }));
            }}
          />
        </div>
      </div>

      {/* Modal */}
      {showExpenseModal && (
        <DailyExpenseModal
          isOpen={showExpenseModal}
          onClose={() => {
            setShowExpenseModal(false);
            setSelectedExpense(null);
          }}
          expense={selectedExpense}
          outletId={currentOutlet}
        />
      )}
    </div>
  );
}