import { createClient, SupabaseClient } from '@supabase/supabase-js';
import { Database } from '../types/supabase';

const supabaseUrl = 'https://qnjdsmgehecigbgxtucj.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InFuamRzbWdlaGVjaWdiZ3h0dWNqIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzMwMzUzNjEsImV4cCI6MjA0ODYxMTM2MX0.HBfvAM2kGtgJJyO0w0_-vzUJfPnwcFiTKJYTKTuE2d8';

if (!supabaseUrl) throw new Error('SUPABASE_URL is required');
if (!supabaseKey) throw new Error('SUPABASE_ANON_KEY is required');

export const supabase: SupabaseClient<Database> = createClient<Database>(supabaseUrl, supabaseKey, {
  auth: {
    persistSession: true,
    autoRefreshToken: true, 
    storage: window.localStorage
  }
});

// Initialize Supabase and set up real-time subscriptions
export async function initializeSupabase() {
  try {
    // Subscribe to auth state changes
    supabase.auth.onAuthStateChange((event, session) => {
      console.log('Auth state changed:', event, session);
    });

    // Subscribe to menu items changes
    const menuSubscription = supabase
      .channel('menu_changes')
      .on(
        'postgres_changes',
        { event: '*', schema: 'public', table: 'menu_items' },
        (payload) => {
          console.log('Menu change received:', payload);
        }
      )
      .subscribe();

    // Subscribe to orders changes
    const orderSubscription = supabase
      .channel('order_changes')
      .on(
        'postgres_changes',
        { event: '*', schema: 'public', table: 'orders' },
        (payload) => {
          console.log('Order change received:', payload);
        }
      )
      .subscribe();

    return { menuSubscription, orderSubscription };
  } catch (error) {
    console.error('Error initializing Supabase:', error);
    return null;
  }
}