import { supabase } from '../supabase-client';
import { Expense, ExpenseCategory } from '../../types/expense';
import { useAuthStore } from '../../store/useAuthStore';
import toast from 'react-hot-toast';

export async function fetchExpenseTypes(outletId: string) {
  try {
    const { data, error } = await supabase
      .from('expense_types')
      .select('*')
      .eq('outlet_id', outletId)
      .eq('is_active', true)
      .order('name');

    if (error) throw error;
    
    return data.map(type => ({
      id: type.id,
      outletId: type.outlet_id,
      name: type.name,
      description: type.description,
      isActive: type.is_active,
      createdAt: new Date(type.created_at),
      updatedAt: new Date(type.updated_at)
    }));
  } catch (error) {
    console.error('Error fetching expense types:', error);
    toast.error('Failed to fetch expense types');
    throw error;
  }
}

export async function createExpenseType(outletId: string, name: string, description?: string) {
  try {
    const { data, error } = await supabase
      .from('expense_types')
      .insert([{
        outlet_id: outletId,
        name,
        description,
        is_active: true,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      }])
      .select()
      .single();

    if (error) throw error;
    toast.success('Expense type created successfully');
    return {
      id: data.id,
      outletId: data.outlet_id,
      name: data.name,
      description: data.description,
      isActive: data.is_active,
      createdAt: new Date(data.created_at),
      updatedAt: new Date(data.updated_at)
    };
  } catch (error) {
    console.error('Error creating expense type:', error);
    toast.error('Failed to create expense type');
    throw error;
  }
}

export async function updateExpenseType(id: string, updates: { name?: string; description?: string; isActive?: boolean }) {
  try {
    const { data, error } = await supabase
      .from('expense_types')
      .update({
        name: updates.name,
        description: updates.description,
        is_active: updates.isActive,
        updated_at: new Date().toISOString()
      })
      .eq('id', id)
      .select()
      .single();

    if (error) throw error;
    toast.success('Expense type updated successfully');
    return {
      id: data.id,
      outletId: data.outlet_id,
      name: data.name,
      description: data.description,
      isActive: data.is_active,
      createdAt: new Date(data.created_at),
      updatedAt: new Date(data.updated_at)
    };
  } catch (error) {
    console.error('Error updating expense type:', error);
    toast.error('Failed to update expense type');
    throw error;
  }
}

export async function deleteExpenseType(id: string) {
  try {
    const { error } = await supabase
      .from('expense_types')
      .update({ is_active: false })
      .eq('id', id);

    if (error) throw error;
    toast.success('Expense type deleted successfully');
  } catch (error) {
    console.error('Error deleting expense type:', error);
    toast.error('Failed to delete expense type');
    throw error;
  }
}

export async function fetchExpenseCategories(outletId: string) {
  try {
    const { data, error } = await supabase
      .from('expense_categories_with_types')
      .select(`
        *,
        subcategories:expense_subcategories(
          id,
          name,
          description,
          is_active,
          quantity,
          unit,
          created_at,
          updated_at
        )
      `)
      .eq('outlet_id', outletId)
      .eq('is_active', true)
      .order('name');

    if (error) throw error;
    
    return data.map(category => ({
      id: category.id,
      outletId: category.outlet_id,
      typeId: category.type_id,
      name: category.name,
      description: category.description,
      isActive: category.is_active,
      subcategories: category.subcategories?.map((sub: any) => ({
        id: sub.id,
        categoryId: sub.category_id,
        outletId: sub.outlet_id,
        name: sub.name,
        description: sub.description,
        isActive: sub.is_active,
        quantity: sub.quantity || 0,
        unit: sub.unit || '',
        createdAt: new Date(sub.created_at),
        updatedAt: new Date(sub.updated_at)
      })) || [],
      createdAt: new Date(category.created_at),
      updatedAt: new Date(category.updated_at)
    }));
  } catch (error) {
    console.error('Error fetching expense categories:', error);
    toast.error('Failed to fetch expense categories');
    throw error;
  }
}

export async function createExpenseSubcategory(
  outletId: string,
  categoryId: string,
  name: string,
  description?: string,
  quantity?: number,
  unit?: string
) {
  try {
    const { data, error } = await supabase
      .from('expense_subcategories')
      .insert([{
        outlet_id: outletId,
        category_id: categoryId,
        name,
        description,
        quantity,
        unit,
        is_active: true,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      }])
      .select()
      .single();

    if (error) throw error;
    toast.success('Subcategory created successfully');
    return {
      id: data.id,
      categoryId: data.category_id,
      outletId: data.outlet_id,
      name: data.name,
      description: data.description,
      isActive: data.is_active,
      quantity: data.quantity,
      unit: data.unit,
      createdAt: new Date(data.created_at),
      updatedAt: new Date(data.updated_at)
    };
  } catch (error) {
    console.error('Error creating expense subcategory:', error);
    toast.error('Failed to create subcategory');
    throw error;
  }
}

export async function updateExpenseSubcategory(
  id: string,
  updates: { name?: string; description?: string; isActive?: boolean; quantity?: number; unit?: string }
) {
  try {
    const { data, error } = await supabase
      .from('expense_subcategories')
      .update({
        name: updates.name,
        description: updates.description,
        is_active: updates.isActive,
        quantity: updates.quantity,
        unit: updates.unit,
        updated_at: new Date().toISOString()
      })
      .eq('id', id)
      .select()
      .single();

    if (error) throw error;
    toast.success('Subcategory updated successfully');
    return {
      id: data.id,
      categoryId: data.category_id,
      outletId: data.outlet_id,
      name: data.name,
      description: data.description,
      isActive: data.is_active,
      quantity: data.quantity,
      unit: data.unit,
      createdAt: new Date(data.created_at),
      updatedAt: new Date(data.updated_at)
    };
  } catch (error) {
    console.error('Error updating expense subcategory:', error);
    toast.error('Failed to update subcategory');
    throw error;
  }
}
export async function createExpenseCategory(outletId: string, typeId: string, name: string, description?: string) {
  try {
    if (!typeId) {
      throw new Error('Type ID is required');
    }

    const { data, error } = await supabase
      .from('expense_categories')
      .insert([{
        outlet_id: outletId,
        type_id: typeId,
        name,
        description,
        is_active: true,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      }])
      .select()
      .single();

    if (error) throw error;
    toast.success('Expense category created successfully');
    return {
      id: data.id,
      outletId: data.outlet_id,
      typeId: data.type_id,
      name: data.name,
      description: data.description,
      isActive: data.is_active,
      createdAt: new Date(data.created_at),
      updatedAt: new Date(data.updated_at)
    };
  } catch (error) {
    console.error('Error creating expense category:', error);
    toast.error('Failed to create expense category');
    throw error;
  }
}

export async function updateExpenseCategory(id: string, updates: { name?: string; description?: string; isActive?: boolean }) {
  try {
    const { data, error } = await supabase
      .from('expense_categories')
      .update({
        name: updates.name,
        description: updates.description,
        is_active: updates.isActive,
        type_id: updates.typeId,
        updated_at: new Date().toISOString()
      })
      .eq('id', id)
      .select()
      .single();

    if (error) throw error;
    toast.success('Expense category updated successfully');
    return {
      id: data.id,
      outletId: data.outlet_id,
      typeId: data.type_id,
      name: data.name,
      description: data.description,
      isActive: data.is_active,
      createdAt: new Date(data.created_at),
      updatedAt: new Date(data.updated_at)
    };
  } catch (error) {
    console.error('Error updating expense category:', error);
    toast.error('Failed to update expense category');
    throw error;
  }
}

export async function fetchExpenses(outletId: string, startDate?: Date, endDate?: Date) {
  try {
    const { data, error } = await supabase
      .from('expenses_with_details')
      .select('*')
      .eq('outlet_id', outletId)
      .gte('created_at', startDate?.toISOString() || '1970-01-01')
      .lte('created_at', endDate?.toISOString() || new Date().toISOString())
      .order('created_at', { ascending: false });

    if (error) throw error;

    return data.map(expense => ({
      id: expense.id,
      outletId: expense.outlet_id,
      quantity: Number(expense.quantity || 0),
      categoryId: expense.category_id,
      typeId: expense.type_id,
      subcategoryId: expense.subcategory_id,
      type: expense.type ? {
        id: expense.type.id,
        name: expense.type_name,
        description: expense.type_description
      } : null,
      category: expense.category_name ? {
        id: expense.category_id,
        name: expense.category_name,
        description: expense.category_description,
        typeId: expense.type_id
      } : null,
      subcategory: expense.subcategory_name ? {
        id: expense.subcategory_id,
        name: expense.subcategory_name,
        description: expense.subcategory_description,
        quantity: expense.subcategory_quantity,
        unit: expense.subcategory_unit
      } : null,
      amount: expense.amount,
      taxAmount: expense.tax_amount,
      description: expense.description,
      date: new Date(expense.date),
      paymentMethod: expense.payment_method,
      referenceNumber: expense.reference_number,
      receiptUrl: expense.receipt_url,
      createdBy: expense.created_by,
      createdByName: expense.created_by_name,
      createdByEmail: expense.created_by_email,
      updatedBy: expense.updated_by,
      createdAt: new Date(expense.created_at),
      updatedAt: new Date(expense.updated_at)
    }));
  } catch (error) {
    console.error('Error fetching expenses:', error);
    toast.error('Failed to fetch expenses');
    throw error;
  }
}

export async function createExpense(expense: Omit<Expense, 'id' | 'createdAt' | 'updatedAt' | 'createdBy' | 'updatedBy'>) {
  try {
    const user = useAuthStore.getState().user;
    if (!user) throw new Error('User not authenticated');

    console.log('Creating expense with data:', expense);
    const { data, error } = await supabase
      .from('expenses')
      .insert([{
        outlet_id: expense.outletId,
        category_id: expense.categoryId,
        subcategory_id: expense.subcategoryId,
        quantity: Number(expense.quantity) || 0,
        amount: expense.amount,
        tax_amount: expense.taxAmount || 0,
        description: expense.description,
        date: expense.date.toISOString(),
        payment_method: expense.paymentMethod,
        reference_number: expense.referenceNumber,
        receipt_url: expense.receiptUrl,
        created_by: user.id,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
      }])
      .select()
      .single();

    if (error) throw error;

    // Fetch the expense with user info
    console.log('Fetching created expense details');
    const { data: expenseWithUser, error: viewError } = await supabase
      .from('expenses_with_details')
      .select('*')
      .eq('id', data.id)
      .single();

    if (viewError) throw viewError;
    console.log('Created expense details:', expenseWithUser);

    toast.success('Expense created successfully');
    return {
      id: expenseWithUser.id,
      outletId: expenseWithUser.outlet_id,
      quantity: Number(expenseWithUser.quantity),
      categoryId: expenseWithUser.category_id,
      category: {
        id: expenseWithUser.category_id,
        name: expenseWithUser.category_name,
        description: expenseWithUser.category_description
      },
      amount: expenseWithUser.amount,
      taxAmount: expenseWithUser.tax_amount,
      description: expenseWithUser.description,
      date: new Date(expenseWithUser.date),
      paymentMethod: expenseWithUser.payment_method,
      referenceNumber: expenseWithUser.reference_number,
      receiptUrl: expenseWithUser.receipt_url,
      createdBy: expenseWithUser.created_by,
      createdByEmail: expenseWithUser.created_by_email,
      createdByName: expenseWithUser.created_by_name || 'Unknown',
      updatedBy: expenseWithUser.updated_by,
      createdAt: new Date(expenseWithUser.created_at),
      updatedAt: new Date(expenseWithUser.updated_at)
    };
  } catch (error) {
    console.error('Error creating expense:', error);
    toast.error('Failed to create expense');
    throw error;
  }
}

export async function updateExpense(id: string, updates: Partial<Omit<Expense, 'id' | 'createdAt' | 'updatedAt' | 'createdBy' | 'updatedBy'>>) {
  try {
    const user = useAuthStore.getState().user;
    if (!user) throw new Error('User not authenticated');

    const { data, error } = await supabase
      .from('expenses')
      .update({
        quantity: Number(updates.quantity) || 0,
        category_id: updates.categoryId,
        subcategory_id: updates.subcategoryId,
        amount: updates.amount,
        tax_amount: updates.taxAmount || 0,
        description: updates.description,
        date: updates.date?.toISOString(),
        payment_method: updates.paymentMethod,
        reference_number: updates.referenceNumber,
        receipt_url: updates.receiptUrl,
        updated_by: user.id,
        updated_at: new Date().toISOString()
      })
      .eq('id', id)
      .select()
      .single();

    if (error) throw error;

    // Fetch the expense with user info
    const { data: expenseWithUser, error: viewError } = await supabase
      .from('expenses_with_details')
      .select('*')
      .eq('id', data?.id)
      .single();

    if (viewError) throw viewError;

    toast.success('Expense updated successfully');
    return {
      ...expenseWithUser,
      quantity: expenseWithUser.quantity || 0,
      createdByName: expenseWithUser.created_by_name || user.name || user.email || 'Unknown',
      date: new Date(expenseWithUser.date),
      createdAt: new Date(expenseWithUser.created_at),
      updatedAt: new Date(expenseWithUser.updated_at)
    };
  } catch (error) {
    console.error('Error updating expense:', error);
    toast.error('Failed to update expense');
    throw error;
  }
}

export async function deleteExpense(id: string) {
  try {
    const { error } = await supabase
      .from('expenses')
      .delete()
      .eq('id', id);

    if (error) throw error;
    toast.success('Expense deleted successfully');
  } catch (error) {
    console.error('Error deleting expense:', error);
    toast.error('Failed to delete expense');
    throw error;
  }
}

export async function deleteExpenseCategory(id: string) {
  try {
    const { error } = await supabase
      .from('expense_categories')
      .delete()
      .eq('id', id);

    if (error) throw error;
    toast.success('Category deleted successfully');
  } catch (error) {
    console.error('Error deleting expense category:', error);
    toast.error('Failed to delete category');
    throw error;
  }
}

export async function deleteExpenseSubcategory(id: string) {
  try {
    const { error } = await supabase
      .from('expense_subcategories')
      .delete()
      .eq('id', id);

    if (error) throw error;
    toast.success('Subcategory deleted successfully');
  } catch (error) {
    console.error('Error deleting expense subcategory:', error);
    toast.error('Failed to delete subcategory');
    throw error;
  }
}