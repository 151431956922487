import React, { useState, useEffect } from 'react';
import { SalesTrendReport } from '../components/reports/SalesTrendReport';
import { ExpenseReport } from '../components/reports/ExpenseReport';
import { ProfitComparisonReport } from '../components/reports/ProfitComparisonReport';
import { ExpenseTypeDistribution } from '../components/reports/ExpenseTypeDistribution';
import { DateRangeFilter } from '../components/orders/DateRangeFilter';
import { useExpenseStore } from '../store/useExpenseStore';
import { useOutletStore } from '../store/useOutletStore';
import { DateRangeType } from '../types';
import { getDateRange, formatCurrency } from '../lib/utils';
import { fetchDailyProfitComparison } from '../lib/api/reports';
import { AlertCircle, RefreshCw } from 'lucide-react';
import toast from 'react-hot-toast';

export function Reports() {
  const [dateRange, setDateRange] = useState<DateRangeType>('thisMonth');
  const [customStartDate, setCustomStartDate] = useState<Date>(new Date());
  const [customEndDate, setCustomEndDate] = useState<Date>(new Date());
  const [isLoading, setIsLoading] = useState(true);
  const [profitData, setProfitData] = useState<any[]>([]);
  const { currentOutlet } = useOutletStore();
  const {
    expenses,
    types: expenseTypes,
    categories: expenseCategories,
    fetchExpenses,
    fetchCategories: fetchExpenseCategories,
    fetchTypes
  } = useExpenseStore();

  const dateRangeValues = React.useMemo(() => getDateRange(
    dateRange, 
    dateRange === 'custom' ? customStartDate : undefined,
    dateRange === 'custom' ? customEndDate : undefined
  ), [dateRange, customStartDate, customEndDate]);

  const loadData = React.useCallback(async () => {
    if (!currentOutlet) return;

    setIsLoading(true);
    
    try {
      await Promise.all([
        fetchExpenses(currentOutlet, dateRangeValues.start, dateRangeValues.end),
        fetchExpenseCategories(currentOutlet),
        fetchTypes(currentOutlet)
      ]);

      const profitComparisonData = await fetchDailyProfitComparison(
        currentOutlet, 
        dateRangeValues.start, 
        dateRangeValues.end
      );

      // Filter out data outside the date range
      const filteredData = profitComparisonData.filter(day => {
        const dayDate = new Date(day.date);
        return dayDate >= dateRangeValues.start && dayDate <= dateRangeValues.end;
      });

      setProfitData(filteredData);
    } catch (error) {
      console.error('Error loading report data:', error);
      toast.error('Some data failed to load. Please try refreshing.');
    } finally {
      setIsLoading(false);
    }
  }, [currentOutlet, dateRangeValues.start, dateRangeValues.end, fetchExpenses, fetchExpenseCategories, fetchTypes]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  if (!currentOutlet) {
    return (
      <div className="flex flex-col items-center justify-center h-[calc(100vh-4rem)]">
        <AlertCircle className="h-16 w-16 text-yellow-500 mb-4" />
        <h2 className="text-2xl font-semibold text-gray-900 mb-2">No Outlet Selected</h2>
        <p className="text-gray-600 mb-4">Please select an outlet to view reports</p>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex flex-col gap-4 md:flex-row md:justify-between md:items-center">
        <div className="flex items-center gap-4">
          <h1 className="text-2xl font-semibold text-gray-900">Reports</h1>
          <button
            onClick={loadData}
            className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            <RefreshCw className="h-4 w-4 mr-2" />
            Refresh
          </button>
        </div>
        <DateRangeFilter
          dateRange={dateRange}
          onDateRangeChange={setDateRange}
          customStartDate={customStartDate}
          customEndDate={customEndDate}
          onCustomStartDateChange={setCustomStartDate}
          onCustomEndDateChange={setCustomEndDate}
        />
      </div>

      {/* Summary Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {profitData.length > 0 && (
          <>
            <div className="bg-white rounded-lg shadow p-6">
              <h3 className="text-sm font-medium text-gray-500">Total Sales</h3>
              <p className="mt-2 text-3xl font-bold text-gray-900">
                {formatCurrency(profitData.reduce((sum, day) => sum + day.totalSales, 0))}
              </p>
              <p className="mt-1 text-sm text-gray-500">
                {profitData.reduce((sum, day) => sum + day.orderCount, 0)} orders
              </p>
            </div>

            <div className="bg-white rounded-lg shadow p-6">
              <h3 className="text-sm font-medium text-gray-500">Total Expenses</h3>
              <p className="mt-2 text-3xl font-bold text-red-600">
                {formatCurrency(profitData.reduce((sum, day) => sum + day.totalExpenses, 0))}
              </p>
              <p className="mt-1 text-sm text-gray-500">
                {profitData.reduce((sum, day) => sum + day.expenseCount, 0)} transactions
              </p>
            </div>

            <div className="bg-white rounded-lg shadow p-6">
              <h3 className="text-sm font-medium text-gray-500">Net Profit</h3>
              <p className="mt-2 text-3xl font-bold text-green-600">
                {formatCurrency(profitData.reduce((sum, day) => sum + day.netProfit, 0))}
              </p>
              <p className="mt-1 text-sm text-gray-500">
                For selected period
              </p>
            </div>

            <div className="bg-white rounded-lg shadow p-6">
              <h3 className="text-sm font-medium text-gray-500">Profit Margin</h3>
              <p className="mt-2 text-3xl font-bold text-indigo-600">
                {((profitData.reduce((sum, day) => sum + day.netProfit, 0) / 
                   profitData.reduce((sum, day) => sum + day.totalSales, 0)) * 100).toFixed(1)}%
              </p>
              <p className="mt-1 text-sm text-gray-500">
                Average for period
              </p>
            </div>
          </>
        )}
      </div>

      <div className="grid grid-cols-1 gap-6">
        {/* Profit Comparison Report */}
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-lg font-semibold mb-4">Profit Analysis</h2>
          <ProfitComparisonReport
            data={profitData}
            dateRange={dateRange}
          />
        </div>

        {/* Expense Type Distribution */}
        <ExpenseTypeDistribution 
          expenses={expenses}
          types={expenseTypes}
        />

        {/* Sales Trend Reports */}
        <div className="bg-white rounded-lg shadow p-6 overflow-hidden">
          <h2 className="text-lg font-semibold mb-4">Sales Trend Analysis</h2>
          <div className="space-y-6">
            <SalesTrendReport
              dateRange={dateRange}
              startDate={dateRangeValues.start}
              endDate={dateRangeValues.end}
              type="payment"
              title="Sales by Payment Method"
            />
          </div>
        </div>

        {/* Expense Reports */}
        {expenseTypes.map(type => (
          <div key={type.id} className="bg-white rounded-lg shadow p-6 overflow-hidden">
            <h2 className="text-lg font-semibold mb-4">{type.name} Expense Analysis</h2>
            <ExpenseReport
              dateRange={dateRange}
              startDate={dateRangeValues.start}
              endDate={dateRangeValues.end}
              typeId={type.id}
            />
          </div>
        ))}
      </div>
    </div>
  );
}