import React, { useState, useEffect } from 'react';
import { useExpenseStore } from '../store/useExpenseStore';
import { useOutletStore } from '../store/useOutletStore';
import { format } from 'date-fns';
import { Plus, AlertCircle, Filter, Download, Settings, ListFilter, DollarSign } from 'lucide-react';
import { ExpenseTypeTree } from '../components/expense/ExpenseTypeTree';
import { ExpenseModal } from '../components/expense/ExpenseModal';
import { ExpenseTypeModal } from '../components/expense/ExpenseTypeModal';
import { ExpenseCategoryModal } from '../components/expense/ExpenseCategoryModal';
import { ExpenseSubcategoryModal } from '../components/expense/ExpenseSubcategoryModal';
import { ExpenseCategoryList } from '../components/expense/ExpenseCategoryList';
import { ExpenseList } from '../components/expense/ExpenseList';
import { ExpenseSummary } from '../components/expense/ExpenseSummary';
import { ExpenseCategory, ExpenseSubcategory, ExpenseType } from '../types/expense';
import { formatCurrency, sortByDate } from '../lib/utils';
import toast from 'react-hot-toast';
import { SearchInput } from '../components/common/SearchInput';

type SortField = 'date' | 'category' | 'amount';
type SortOrder = 'asc' | 'desc';

export function ExpenseManagement() {
  const { expenses, types, categories, fetchExpenses, fetchTypes, fetchCategories } = useExpenseStore();
  const { currentOutlet } = useOutletStore();
  const [searchTerm, setSearchTerm] = useState('');
  const [showExpenseModal, setShowExpenseModal] = useState(false);
  const [showTypeModal, setShowTypeModal] = useState(false);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [showSubcategoryModal, setShowSubcategoryModal] = useState(false);
  const [selectedExpense, setSelectedExpense] = useState(null);
  const [selectedType, setSelectedType] = useState<ExpenseType | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<ExpenseCategory | null>(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState<ExpenseSubcategory | null>(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>(null);
  const [selectedTypeId, setSelectedTypeId] = useState<string | null>(null);
  const [selectedCategoryFilter, setSelectedCategoryFilter] = useState<string>('all');
  const [selectedTypeFilter, setSelectedTypeFilter] = useState<string>('all');
  const [view, setView] = useState<'expenses' | 'types' | 'categories'>('expenses');
  const [sortConfig, setSortConfig] = useState<{ field: SortField; order: SortOrder }>({
    field: 'date',
    order: 'desc'
  });
  const [dateRange, setDateRange] = useState({
    start: (() => {
      const date = new Date();
      date.setHours(0, 0, 0, 0);
      return new Date(date.getFullYear(), date.getMonth(), 1);
    })(),
    end: (() => {
      const date = new Date();
      date.setHours(23, 59, 59, 999);
      return new Date(date.getFullYear(), date.getMonth() + 1, 0);
    })()
  });

  const handleDateChange = (type: 'start' | 'end', date: string) => {
    const newDate = new Date(date);
    if (type === 'end') {
      newDate.setHours(23, 59, 59, 999);
    } else {
      newDate.setHours(0, 0, 0, 0);
    }
    setDateRange(prev => ({
      ...prev,
      [type]: newDate
    }));
  };

  const handleEditSubcategory = (subcategory: ExpenseSubcategory, categoryId: string) => {
    setSelectedSubcategory(subcategory);
    setSelectedCategoryId(categoryId);
    setShowSubcategoryModal(true);
  };

  const handleAddSubcategory = (categoryId: string) => {
    setSelectedSubcategory(null);
    setSelectedCategoryId(categoryId);
    setShowSubcategoryModal(true);
  };

  useEffect(() => {
    if (currentOutlet) {
      fetchTypes(currentOutlet);
      fetchCategories(currentOutlet);
      fetchExpenses(currentOutlet, dateRange.start, dateRange.end);
    }
  }, [currentOutlet, dateRange, fetchTypes, fetchCategories, fetchExpenses]);

  const sortedAndFilteredExpenses = expenses.filter(expense => {
    const searchLower = searchTerm.toLowerCase();
    const typeMatch = selectedTypeFilter === 'all' || expense.category?.typeId === selectedTypeFilter;
    const categoryMatch = selectedCategoryFilter === 'all' || expense.categoryId === selectedCategoryFilter;
    const searchMatch = 
      expense.description?.toLowerCase().includes(searchLower) ||
      expense.category?.name.toLowerCase().includes(searchLower) ||
      expense.referenceNumber?.toLowerCase().includes(searchLower) ||
      expense.createdByName?.toLowerCase().includes(searchLower);
      
    return typeMatch && categoryMatch && searchMatch;
  }).sort((a, b) => {
    const { field, order } = sortConfig;
    const multiplier = order === 'desc' ? -1 : 1;

    switch (field) {
      case 'date':
        return multiplier * (new Date(b.date).getTime() - new Date(a.date).getTime());
      case 'category':
        return multiplier * (a.category?.name || '').localeCompare(b.category?.name || '');
      case 'subcategory':
        return multiplier * ((a.subcategory?.name || '') || '').localeCompare((b.subcategory?.name || '') || '');
      case 'amount':
        return multiplier * (a.amount - b.amount);
      default:
        return 0;
    }
  });

  const handleExport = () => {
    // TODO: Implement CSV export
    toast.success('Export feature coming soon!');
  };

  if (!currentOutlet) {
    return (
      <div className="flex flex-col items-center justify-center h-[calc(100vh-4rem)]">
        <AlertCircle className="h-16 w-16 text-yellow-500 mb-4" />
        <h2 className="text-2xl font-semibold text-gray-900 mb-2">No Outlet Selected</h2>
        <p className="text-gray-600 mb-4">Please select an outlet to manage expenses</p>
      </div>
    );
  }

  const totalExpenses = expenses.reduce((sum, exp) => sum + exp.amount, 0);
  const totalTax = expenses.reduce((sum, exp) => sum + exp.taxAmount, 0);

  return (
    <div className="space-y-6">
      {/* Header Section */}
      <div className="bg-white rounded-xl shadow-sm p-6">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
          <div>
            <h1 className="text-xl font-semibold text-gray-900 flex items-center gap-3">
              <DollarSign className="h-8 w-8 text-indigo-600" />
              {view === 'types' ? 'Expense Types' : 
               view === 'categories' ? 'Expense Categories' : 
               'Expense Management'}
            </h1>
            {view === 'expenses' && (
              <p className="mt-2 text-sm text-gray-500">
                Total Expenses: {formatCurrency(totalExpenses)} (Tax: {formatCurrency(totalTax)})
              </p>
            )}
          </div>
          <div className="flex flex-wrap items-center gap-4">
            {view === 'expenses' && (
              <SearchInput
                value={searchTerm}
                onChange={setSearchTerm}
                placeholder="Search expenses..."
                className="w-64"
              />
            )}
            <button
              onClick={() => setView(view === 'expenses' ? 'types' : 'expenses')}
              className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-lg text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 transition-colors"
            >
              <ListFilter className="h-5 w-5 mr-2" />
              {view === 'expenses' ? 'Manage Types' : 'View Expenses'}
            </button>
            {view === 'expenses' && (
              <>
                <button
                  onClick={handleExport}
                  className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-lg text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 transition-colors"
                >
                  <Download className="h-5 w-5 mr-2" />
                  Export
                </button>
                <button
                  onClick={() => {
                    setSelectedExpense(null);
                    setShowExpenseModal(true);
                  }}
                  className="inline-flex items-center px-4 py-2 border border-transparent rounded-lg text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 transition-colors"
                >
                  <Plus className="h-5 w-5 mr-2" />
                  Add Expense
                </button>
              </>
            )}
            {view === 'types' && (
              <button
                onClick={() => {
                  setSelectedType(null);
                  setShowTypeModal(true);
                }}
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-lg text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 transition-colors"
              >
                <Plus className="h-5 w-5 mr-2" />
                Add Type
              </button>
            )}
          </div>
        </div>
      </div>

      {/* Main Content */}
      {view === 'types' ? (
        <ExpenseTypeTree
          types={types}
          categories={categories}
          onEditType={(type) => {
            setSelectedType(type);
            setShowTypeModal(true);
          }}
          onEditCategory={(category) => {
            setSelectedCategory(category);
            setShowCategoryModal(true);
          }}
          onEditSubcategory={handleEditSubcategory}
          onAddCategory={(typeId) => {
            setSelectedTypeId(typeId);
            setShowCategoryModal(true);
          }}
          onAddSubcategory={handleAddSubcategory}
          onDeleteType={async (type) => {
            if (window.confirm('Are you sure you want to delete this type? This will also delete all associated categories and subcategories.')) {
              try {
                await useExpenseStore.getState().deleteType(type.id);
                toast.success('Type deleted successfully');
              } catch (error) {
                console.error('Error deleting type:', error);
                toast.error('Failed to delete type');
              }
            }
          }}
          onDeleteCategory={async (category) => {
            if (window.confirm('Are you sure you want to delete this category? This will also delete all subcategories.')) {
              try {
                await useExpenseStore.getState().deleteCategory(category.id);
                toast.success('Category deleted successfully');
              } catch (error) {
                console.error('Error deleting category:', error);
                toast.error('Failed to delete category');
              }
            }
          }}
          onDeleteSubcategory={async (subcategory, categoryId) => {
            if (window.confirm('Are you sure you want to delete this subcategory?')) {
              try {
                await useExpenseStore.getState().deleteSubcategory(subcategory.id, categoryId);
                toast.success('Subcategory deleted successfully');
              } catch (error) {
                console.error('Error deleting subcategory:', error);
                toast.error('Failed to delete subcategory');
              }
            }
          }}
        />
      ) : view === 'categories' ? (
        <div>
          <ExpenseCategoryList
            categories={categories}
            className="bg-white rounded-lg shadow-sm"
            onEdit={(category) => {
              setSelectedCategory(category);
              setShowCategoryModal(true);
            }}
            onEditSubcategory={handleEditSubcategory}
            onAddSubcategory={handleAddSubcategory}
          />
        </div> 
      ) : (
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-8">
          {/* Summary */}
          <div className="lg:col-span-1 order-2 lg:order-1">
            <ExpenseSummary expenses={expenses} categories={categories} />
          </div>

          {/* Expense List */}
          <div className="lg:col-span-3 order-1 lg:order-2">
            <div className="bg-white p-8 rounded-xl shadow-sm border border-gray-100">
              <div className="flex flex-wrap items-center gap-4 mb-6">
                <Filter className="h-5 w-5 text-gray-400 flex-shrink-0" />
                <div className="flex flex-wrap items-center gap-4">
                  <div className="w-48">
                    <label className="block text-sm font-medium text-gray-700">Type</label>
                    <select
                      value={selectedTypeFilter}
                      onChange={(e) => {
                        setSelectedTypeFilter(e.target.value);
                        setSelectedCategoryFilter('all'); // Reset category when type changes
                      }}
                      className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    >
                      <option value="all">All Types</option>
                      {types.map((type) => (
                        <option key={type.id} value={type.id}>
                          {type.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="w-40">
                    <label className="block text-sm font-medium text-gray-700">Start Date</label>
                    <input
                      type="date"
                      value={format(dateRange.start, 'yyyy-MM-dd')}
                      onChange={(e) => handleDateChange('start', e.target.value)}
                      className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    />
                  </div>
                  <div className="w-40">
                    <label className="block text-sm font-medium text-gray-700">End Date</label>
                    <input
                      type="date"
                      value={format(dateRange.end, 'yyyy-MM-dd')}
                      onChange={(e) => handleDateChange('end', e.target.value)}
                      className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    />
                  </div>
                  <div className="w-48">
                    <label className="block text-sm font-medium text-gray-700">Category</label>
                    <select
                      value={selectedCategoryFilter}
                      onChange={(e) => setSelectedCategoryFilter(e.target.value)}
                      className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    >
                      <option value="all">All Categories</option>
                      {categories
                        .filter(cat => selectedTypeFilter === 'all' || cat.typeId === selectedTypeFilter)
                        .map((category) => (
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <ExpenseList
                expenses={sortedAndFilteredExpenses}
                sortConfig={sortConfig}
                onSort={(field) => {
                  setSortConfig(prev => ({
                    field,
                    order: prev.field === field && prev.order === 'desc' ? 'asc' : 'desc'
                  }));
                }}
                onEdit={(expense) => {
                  setSelectedExpense(expense);
                  setShowExpenseModal(true);
                }}
              />
            </div>
          </div>
        </div>)}

      {/* Modals */}
      {showTypeModal && (
        <ExpenseTypeModal
          isOpen={showTypeModal}
          onClose={() => {
            setShowTypeModal(false);
            setSelectedType(null);
          }}
          type={selectedType}
          onSubmit={async (data) => {
            try {
              if (selectedType) {
                await useExpenseStore.getState().updateType(selectedType.id, data);
              } else {
                await useExpenseStore.getState().addType(currentOutlet, data.name, data.description);
              }
              setShowTypeModal(false);
              setSelectedType(null);
            } catch (error) {
              console.error('Error saving type:', error);
            }
          }}
        />
      )}

      {showExpenseModal && (
        <ExpenseModal
          isOpen={showExpenseModal}
          onClose={() => {
            setShowExpenseModal(false);
            setSelectedExpense(null);
          }}
          expense={selectedExpense}
          categories={categories}
          types={types}
          outletId={currentOutlet}
        />
      )}

      {showCategoryModal && (
        <ExpenseCategoryModal
          isOpen={showCategoryModal}
          onClose={() => {
            setShowCategoryModal(false);
            setSelectedCategory(null);
          }}
          selectedTypeId={selectedTypeId}
          types={types}
          category={selectedCategory}
          outletId={currentOutlet}
        />
      )}
      
      {showSubcategoryModal && selectedCategoryId && (
        <ExpenseSubcategoryModal
          isOpen={showSubcategoryModal}
          onClose={() => {
            setShowSubcategoryModal(false);
            setSelectedSubcategory(null);
            setSelectedCategoryId(null);
          }}
          onSubmit={async (data) => {
            try {
              if (selectedSubcategory) {
                await useExpenseStore.getState().updateSubcategory(selectedSubcategory.id, {
                  name: data.name,
                  description: data.description,
                  isActive: data.isActive,
                  quantity: data.quantity,
                  unit: data.unit
                });
              } else {
                await useExpenseStore.getState().addSubcategory(
                  currentOutlet,
                  selectedCategoryId,
                  data.name,
                  data.description,
                  data.quantity,
                  data.unit
                );
              }
              setShowSubcategoryModal(false);
              setSelectedSubcategory(null);
              setSelectedCategoryId(null);
            } catch (error) {
              console.error('Error saving subcategory:', error);
            }
          }}
          subcategory={selectedSubcategory}
          categoryName={categories.find(c => c.id === selectedCategoryId)?.name || ''}
        />
      )}
    </div>
  );
}