import { create } from 'zustand';
import { Expense, ExpenseCategory, ExpenseSubcategory, ExpenseType } from '../types/expense';
import { supabase } from '../lib/supabase-client';
import toast from 'react-hot-toast';
import {
  fetchExpenseCategories,
  createExpenseCategory,
  updateExpenseCategory,
  fetchExpenses,
  createExpense,
  updateExpense,
  deleteExpense, 
  fetchExpenseTypes,
  createExpenseSubcategory,
  updateExpenseSubcategory
} from '../lib/api/expenses';

interface ExpenseStore {
  expenses: Expense[];
  types: ExpenseType[];
  categories: ExpenseCategory[];
  subcategories: ExpenseSubcategory[];
  isLoading: boolean;
  error: Error | null;
  fetchTypes: (outletId: string) => Promise<ExpenseType[]>;
  addType: (outletId: string, name: string, description?: string) => Promise<void>;
  updateType: (id: string, updates: { name?: string; description?: string; isActive?: boolean }) => Promise<void>;
  deleteType: (id: string) => Promise<void>;
  fetchCategories: (outletId: string) => Promise<ExpenseCategory[]>;
  addCategory: (outletId: string, typeId: string, name: string, description?: string) => Promise<void>;
  updateCategory: (id: string, updates: { name?: string; description?: string; isActive?: boolean }) => Promise<void>;
  addSubcategory: (outletId: string, categoryId: string, name: string, description?: string) => Promise<void>;
  updateSubcategory: (id: string, updates: { name?: string; description?: string; isActive?: boolean }) => Promise<void>;
  deleteCategory: (id: string) => Promise<void>;
  deleteSubcategory: (id: string, categoryId: string) => Promise<void>;
  fetchExpenses: (outletId: string, startDate?: Date, endDate?: Date) => Promise<void>;
  addExpense: (expense: Omit<Expense, 'id' | 'createdAt' | 'updatedAt' | 'createdBy' | 'updatedBy'>) => Promise<void>;
  updateExpense: (id: string, updates: Partial<Omit<Expense, 'id' | 'createdAt' | 'updatedAt' | 'createdBy' | 'updatedBy'>>) => Promise<void>;
  deleteExpense: (id: string) => Promise<void>;
}

export const useExpenseStore = create<ExpenseStore>((set) => ({
  expenses: [],
  types: [],
  categories: [],
  subcategories: [],
  isLoading: false,
  error: null,

  fetchTypes: async (outletId: string) => {
    try {
      set({ isLoading: true, error: null });
      const types = await fetchExpenseTypes(outletId);
      set({ types, isLoading: false });
      return types;
    } catch (error) {
      set({ error: error as Error, isLoading: false });
      throw error;
    }
  },

  addType: async (outletId: string, name: string, description?: string) => {
    try {
      set({ isLoading: true, error: null });
      const { data, error } = await supabase
        .from('expense_types')
        .insert([{
          outlet_id: outletId,
          name,
          description,
          is_active: true
        }])
        .select()
        .single();

      if (error) throw error;

      const newType = {
        id: data.id,
        outletId: data.outlet_id,
        name: data.name,
        description: data.description,
        isActive: data.is_active,
        createdAt: new Date(data.created_at),
        updatedAt: new Date(data.updated_at)
      };

      set(state => ({ types: [...state.types, newType], isLoading: false }));
      toast.success('Expense type created successfully');
    } catch (error) {
      set({ error: error as Error, isLoading: false });
      throw error;
    }
  },

  updateType: async (id: string, updates: { name?: string; description?: string; isActive?: boolean }) => {
    try {
      set({ isLoading: true, error: null });
      const { data, error } = await supabase
        .from('expense_types')
        .update({
          name: updates.name,
          description: updates.description,
          is_active: updates.isActive,
          updated_at: new Date().toISOString()
        })
        .eq('id', id)
        .select()
        .single();

      if (error) throw error;

      const updatedType = {
        id: data.id,
        outletId: data.outlet_id,
        name: data.name,
        description: data.description,
        isActive: data.is_active,
        createdAt: new Date(data.created_at),
        updatedAt: new Date(data.updated_at)
      };

      set(state => ({
        types: state.types.map(type => type.id === id ? updatedType : type),
        isLoading: false
      }));
      toast.success('Expense type updated successfully');
    } catch (error) {
      set({ error: error as Error, isLoading: false });
      throw error;
    }
  },

  deleteType: async (id: string) => {
    try {
      set({ isLoading: true, error: null });
      const { error } = await supabase
        .from('expense_types')
        .update({ is_active: false })
        .eq('id', id);

      if (error) throw error;

      set(state => ({
        types: state.types.filter(type => type.id !== id),
        isLoading: false
      }));
      toast.success('Expense type deleted successfully');
    } catch (error) {
      set({ error: error as Error, isLoading: false });
      throw error;
    }
  },
  fetchCategories: async (outletId: string) => {
    try {
      set({ isLoading: true, error: null });
      const data = await fetchExpenseCategories(outletId);
      
      const subcategories = data.flatMap(cat => cat.subcategories || []);
      set({ 
        categories: data,
        subcategories,
        isLoading: false 
      });
      return data;
    } catch (error) {
      set({ error: error as Error, isLoading: false });
      throw error;
    }
  },

  addCategory: async (outletId: string, typeId: string, name: string, description?: string) => {
    try {
      set({ isLoading: true, error: null });
      const data = await createExpenseCategory(outletId, typeId, name, description);
      set((state) => ({
        categories: [...state.categories, data],
        isLoading: false,
      }));
    } catch (error) {
      set({ error: error as Error, isLoading: false });
      throw error;
    }
  },

  updateCategory: async (id: string, updates: { name?: string; description?: string; isActive?: boolean }) => {
    try {
      set({ isLoading: true, error: null });
      const data = await updateExpenseCategory(id, updates);
      set((state) => ({
        categories: state.categories.map((cat) =>
          cat.id === id ? { ...cat, ...data } : cat
        ),
        isLoading: false,
      }));
    } catch (error) {
      set({ error: error as Error, isLoading: false });
      throw error;
    }
  },

  addSubcategory: async (outletId: string, categoryId: string, name: string, description?: string) => {
    try {
      set({ isLoading: true, error: null });
      const data = await createExpenseSubcategory(outletId, categoryId, name, description);
      set((state) => ({
        categories: state.categories.map(cat => 
          cat.id === categoryId ? {
            ...cat,
            subcategories: [...(cat.subcategories || []), data]
          } : cat
        ),
        subcategories: [...state.subcategories, data],
        isLoading: false,
      }));
    } catch (error) {
      set({ error: error as Error, isLoading: false });
      throw error;
    }
  },

  updateSubcategory: async (id: string, updates) => {
    try {
      set({ isLoading: true, error: null });
      const data = await updateExpenseSubcategory(id, updates);
      set((state) => ({
        categories: state.categories.map(cat => ({
          ...cat,
          subcategories: cat.subcategories?.map(sub =>
            sub.id === id ? data : sub
          )
        })),
        subcategories: state.subcategories.map(sub =>
          sub.id === id ? data : sub
        ),
        isLoading: false,
      }));
    } catch (error) {
      set({ error: error as Error, isLoading: false });
      throw error;
    }
  },

  deleteCategory: async (id: string) => {
    try {
      set({ isLoading: true, error: null });
      await deleteExpenseCategory(id);
      set((state) => ({
        categories: state.categories.filter((cat) => cat.id !== id),
        subcategories: state.subcategories.filter((sub) => sub.categoryId !== id),
        isLoading: false,
      }));
    } catch (error) {
      set({ error: error as Error, isLoading: false });
      throw error;
    }
  },

  deleteSubcategory: async (id: string, categoryId: string) => {
    try {
      set({ isLoading: true, error: null });
      await deleteExpenseSubcategory(id);
      set((state) => ({
        categories: state.categories.map(cat => 
          cat.id === categoryId ? {
            ...cat,
            subcategories: cat.subcategories?.filter(sub => sub.id !== id) || []
          } : cat
        ),
        subcategories: state.subcategories.filter(sub => sub.id !== id),
        isLoading: false,
      }));
    } catch (error) {
      set({ error: error as Error, isLoading: false });
      throw error;
    }
  },

  fetchExpenses: async (outletId: string, startDate?: Date, endDate?: Date) => {
    try {
      set({ isLoading: true, error: null });
      const expenses = await fetchExpenses(outletId, startDate, endDate);
      
      // Ensure all expense data is properly structured
      const validatedExpenses = expenses.map(expense => ({
        ...expense,
        category: expense.category || null,
        subcategory: expense.subcategory || null
      }));
      
      set({ expenses: validatedExpenses, isLoading: false });
    } catch (error) {
      set({ error: error as Error, isLoading: false });
    }
  },

  addExpense: async (expense) => {
    try {
      set({ isLoading: true, error: null });
      const data = await createExpense(expense);
      set((state) => ({
        expenses: [data, ...state.expenses],
        isLoading: false,
      }));
    } catch (error) {
      set({ error: error as Error, isLoading: false });
      throw error;
    }
  },

  updateExpense: async (id, updates) => {
    try {
      set({ isLoading: true, error: null });
      const data = await updateExpense(id, updates);
      set((state) => ({
        expenses: state.expenses.map((exp) =>
          exp.id === id ? data : exp
        ),
        isLoading: false,
      }));
    } catch (error) {
      set({ error: error as Error, isLoading: false });
      throw error;
    }
  },

  deleteExpense: async (id) => {
    try {
      set({ isLoading: true, error: null });
      await deleteExpense(id);
      set((state) => ({
        expenses: state.expenses.filter((exp) => exp.id !== id),
        isLoading: false,
      }));
    } catch (error) {
      set({ error: error as Error, isLoading: false });
      throw error;
    }
  },
}));