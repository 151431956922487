import { supabase } from '../supabase-client';
import { DailyExpense } from '../../types/expense';
import { useAuthStore } from '../../store/useAuthStore';
import toast from 'react-hot-toast';

export async function fetchDailyExpenses(outletId: string, startDate?: Date, endDate?: Date) {
  try {
    let query = supabase
      .from('daily_expenses_with_users')
      .select('*')
      .eq('outlet_id', outletId)
      .order('date', { ascending: false });

    if (startDate) {
      query = query.gte('date', startDate.toISOString().split('T')[0]);
    }
    if (endDate) {
      query = query.lte('date', endDate.toISOString().split('T')[0]);
    }

    const { data, error } = await query;

    if (error) throw error;

    return data.map(expense => ({
      id: expense.id,
      outletId: expense.outlet_id,
      amount: expense.amount,
      date: new Date(expense.date),
      description: expense.description,
      createdBy: expense.created_by,
      createdByName: expense.created_by_name || expense.created_by_email || 'System',
      updatedBy: expense.updated_by,
      updatedByName: expense.updated_by_name || expense.updated_by_email,
      createdAt: new Date(expense.created_at),
      updatedAt: new Date(expense.updated_at)
    }));
  } catch (error) {
    console.error('Error fetching daily expenses:', error);
    toast.error('Failed to fetch daily expenses');
    throw error;
  }
}

export async function createDailyExpense(expense: Omit<DailyExpense, 'id' | 'createdAt' | 'updatedAt' | 'createdBy' | 'updatedBy' | 'createdByName' | 'updatedByName'>) {
  try {
    const user = useAuthStore.getState().user;
    if (!user) throw new Error('User not authenticated');

    const { data, error } = await supabase
      .from('daily_expenses')
      .insert([{
        outlet_id: expense.outletId,
        amount: expense.amount,
        date: expense.date.toISOString().split('T')[0],
        description: expense.description,
        created_by: user.id,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      }])
      .select()
      .single();

    if (error) throw error;

    toast.success('Daily expense created successfully');
    return {
      ...data,
      date: new Date(data.date),
      createdAt: new Date(data.created_at),
      updatedAt: new Date(data.updated_at),
      createdByName: user.name || user.email || 'System'
    };
  } catch (error) {
    console.error('Error creating daily expense:', error);
    toast.error('Failed to create daily expense');
    throw error;
  }
}

export async function updateDailyExpense(id: string, updates: Partial<Omit<DailyExpense, 'id' | 'createdAt' | 'updatedAt' | 'createdBy' | 'updatedBy' | 'createdByName' | 'updatedByName'>>) {
  try {
    const user = useAuthStore.getState().user;
    if (!user) throw new Error('User not authenticated');

    const { data, error } = await supabase
      .from('daily_expenses')
      .update({
        amount: updates.amount,
        date: updates.date?.toISOString().split('T')[0],
        description: updates.description,
        updated_by: user.id,
        updated_at: new Date().toISOString()
      })
      .eq('id', id)
      .select()
      .single();

    if (error) throw error;

    toast.success('Daily expense updated successfully');
    return {
      ...data,
      date: new Date(data.date),
      createdAt: new Date(data.created_at),
      updatedAt: new Date(data.updated_at),
      updatedByName: user.name || user.email || 'System'
    };
  } catch (error) {
    console.error('Error updating daily expense:', error);
    toast.error('Failed to update daily expense');
    throw error;
  }
}

export async function deleteDailyExpense(id: string) {
  try {
    const { error } = await supabase
      .from('daily_expenses')
      .delete()
      .eq('id', id);

    if (error) throw error;
    toast.success('Daily expense deleted successfully');
  } catch (error) {
    console.error('Error deleting daily expense:', error);
    toast.error('Failed to delete daily expense');
    throw error;
  }
}