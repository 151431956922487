import React from 'react';
import { format } from 'date-fns';
import { Edit2, Trash2, ArrowUp, ArrowDown, Settings } from 'lucide-react';
import { Expense } from '../../types/expense';
import { formatCurrency } from '../../lib/utils';
import { useExpenseStore } from '../../store/useExpenseStore';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useState } from 'react';

type SortField = 'date' | 'category' | 'subcategory' | 'amount';
type SortOrder = 'asc' | 'desc';

interface ColumnVisibility {
  date: boolean;
  type: boolean;
  category: boolean;
  subcategory: boolean;
  description: boolean;
  amount: boolean;
  tax: boolean;
  payment: boolean;
  reference: boolean;
  createdBy: boolean;
}

interface ExpenseListProps {
  expenses: Expense[];
  onEdit: (expense: Expense) => void;
  sortConfig: { field: SortField; order: SortOrder };
  onSort: (field: SortField) => void;
}

export function ExpenseList({ expenses, onEdit, sortConfig, onSort }: ExpenseListProps) {
  const { deleteExpense } = useExpenseStore();
  const [showColumnSettings, setShowColumnSettings] = useState(false);
  const [columnVisibility, setColumnVisibility] = useState<ColumnVisibility>({
    date: true,
    type: true,
    category: true,
    description: true,
    amount: true,
    tax: true,
    payment: true,
    reference: true,
    createdBy: true
  });

  const handleDelete = async (expense: Expense) => {
    if (window.confirm('Are you sure you want to delete this expense?')) {
      try {
        await deleteExpense(expense.id);
        toast.success('Expense deleted successfully');
      } catch (error) {
        console.error('Error deleting expense:', error);
        toast.error('Failed to delete expense');
      }
    }
  };

  const SortIcon = ({ field }: { field: SortField }) => {
    if (sortConfig.field !== field) return null;
    return sortConfig.order === 'desc' ? 
      <ArrowDown className="h-4 w-4 ml-1" /> : 
      <ArrowUp className="h-4 w-4 ml-1" />;
  };

  return (
    <div className="bg-white shadow-sm rounded-xl overflow-hidden border border-gray-100">
      <div className="p-4 border-b border-gray-200 flex justify-between items-center">
        <h3 className="text-lg font-medium text-gray-900">Expense Transactions</h3>
        <button
          onClick={() => setShowColumnSettings(!showColumnSettings)}
          className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
        >
          <Settings className="h-5 w-5 text-gray-500" />
        </button>
      </div>

      {showColumnSettings && (
        <div className="p-4 bg-gray-50 border-b border-gray-200">
          <h4 className="text-sm font-medium text-gray-700 mb-2">Show/Hide Columns</h4>
          <div className="grid grid-cols-2 md:grid-cols-5 gap-4">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={columnVisibility.date}
                onChange={(e) => setColumnVisibility(prev => ({ ...prev, date: e.target.checked }))}
                className="rounded border-gray-300 text-indigo-600"
              />
              <span className="text-sm">Date</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={columnVisibility.type}
                onChange={(e) => setColumnVisibility(prev => ({ ...prev, type: e.target.checked }))}
                className="rounded border-gray-300 text-indigo-600"
              />
              <span className="text-sm">Type</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={columnVisibility.category}
                onChange={(e) => setColumnVisibility(prev => ({ ...prev, category: e.target.checked }))}
                className="rounded border-gray-300 text-indigo-600"
              />
              <span className="text-sm">Category</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={columnVisibility.description}
                onChange={(e) => setColumnVisibility(prev => ({ ...prev, description: e.target.checked }))}
                className="rounded border-gray-300 text-indigo-600"
              />
              <span className="text-sm">Description</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={columnVisibility.amount}
                onChange={(e) => setColumnVisibility(prev => ({ ...prev, amount: e.target.checked }))}
                className="rounded border-gray-300 text-indigo-600"
              />
              <span className="text-sm">Amount</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={columnVisibility.tax}
                onChange={(e) => setColumnVisibility(prev => ({ ...prev, tax: e.target.checked }))}
                className="rounded border-gray-300 text-indigo-600"
              />
              <span className="text-sm">Tax</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={columnVisibility.payment}
                onChange={(e) => setColumnVisibility(prev => ({ ...prev, payment: e.target.checked }))}
                className="rounded border-gray-300 text-indigo-600"
              />
              <span className="text-sm">Payment</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={columnVisibility.reference}
                onChange={(e) => setColumnVisibility(prev => ({ ...prev, reference: e.target.checked }))}
                className="rounded border-gray-300 text-indigo-600"
              />
              <span className="text-sm">Reference</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={columnVisibility.createdBy}
                onChange={(e) => setColumnVisibility(prev => ({ ...prev, createdBy: e.target.checked }))}
                className="rounded border-gray-300 text-indigo-600"
              />
              <span className="text-sm">Created By</span>
            </label>
          </div>
        </div>
      )}

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gradient-to-r from-gray-50 to-white border-b border-gray-200">
            <tr>
              {columnVisibility.date && <th 
                className="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                onClick={() => onSort('date')}
              >
                <div className="flex items-center">
                  Date
                  <SortIcon field="date" />
                </div>
              </th>}
              {columnVisibility.type && <th 
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Type
              </th>}
              {columnVisibility.category && <th 
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                onClick={() => onSort('category')}
              >
                <div className="flex items-center">
                  Category
                  <SortIcon field="category" />
                </div>
              </th>}
              {columnVisibility.description && <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Description
              </th>}
              {columnVisibility.amount && <th 
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                onClick={() => onSort('amount')}
              >
                <div className="flex items-center">
                  Amount
                  <SortIcon field="amount" />
                </div>
              </th>}
              {columnVisibility.payment && <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Payment Method
              </th>}
              {columnVisibility.createdBy && <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Added By
              </th>}
              <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {expenses.map((expense) => (
              <tr key={expense.id} className="hover:bg-gray-50">
                {columnVisibility.date && <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {format(new Date(expense.date), 'dd/MM/yyyy')}
                  <div className="text-xs text-gray-500">
                    {format(new Date(expense.createdAt), 'HH:mm')}
                  </div>
                </td>}
                {columnVisibility.type && <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {expense.type?.name || '-'}
                </td>}
                {columnVisibility.category && <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  <div>
                    {expense.category?.name || '-'}
                    {expense.subcategory && (
                      <div className="text-xs text-gray-500">
                        {expense.subcategory.name}
                        {Number(expense.quantity) > 0 && expense.subcategory.unit && (
                          <span className="ml-1">
                            • {expense.quantity} {expense.subcategory.unit}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                </td>}
                {columnVisibility.description && <td className="px-6 py-4 text-sm text-gray-900">
                  {expense.description || '-'}
                </td>}
                {columnVisibility.amount && <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  <div>{formatCurrency(expense.amount)}</div>
                  {Number(expense.quantity) > 0 && expense.subcategory?.unit && (
                    <div className="text-xs text-gray-500">
                      {formatCurrency(expense.amount / expense.quantity)}/{expense.subcategory?.unit || 'unit'}
                    </div>
                  )}
                  {expense.taxAmount > 0 && (
                    <div className="text-xs text-gray-500">
                      Tax: {formatCurrency(expense.taxAmount)}
                    </div>
                  )}
                </td>}
                {columnVisibility.payment && <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 capitalize">
                  {expense.paymentMethod?.toLowerCase().replace(/_/g, ' ') || '-'}
                  {expense.referenceNumber && (
                    <div className="text-xs text-gray-500">
                      Ref: {expense.referenceNumber}
                    </div>
                  )}
                </td>}
                {columnVisibility.createdBy && <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {expense.createdByEmail || expense.createdByName || 'System'}
                  <div className="text-xs text-gray-500">
                    {format(new Date(expense.createdAt), 'dd/MM/yyyy HH:mm')}
                  </div>
                </td>}
                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <button
                    onClick={() => onEdit(expense)}
                    className="text-indigo-600 hover:text-indigo-900 mr-3"
                  >
                    <Edit2 className="h-4 w-4" />
                  </button>
                  <button
                    onClick={() => handleDelete(expense)}
                    className="text-red-600 hover:text-red-900"
                  >
                    <Trash2 className="h-4 w-4" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      
      {expenses.length === 0 && (
        <div className="text-center py-8">
          <p className="text-gray-500">No expenses found</p>
        </div>
      )}

      <div className="px-6 py-4 bg-gray-50 border-t border-gray-200">
        <Link
          to="/expenses/transactions"
          className="text-sm text-indigo-600 hover:text-indigo-900 font-medium"
        >
          View All Transactions →
        </Link>
      </div>
    </div>
  );
}