import { supabase } from '../supabase-client';
import { InventoryItem, InventoryTransaction, InventoryAlert } from '../../types/inventory';
import { getAuthStore } from '../../store/useAuthStore';
import toast from 'react-hot-toast';

export async function fetchInventoryAlerts(outletId: string) {
  try {
    const { data, error } = await supabase
      .from('inventory_alerts_with_details')
      .select('*')
      .eq('outlet_id', outletId)
      .eq('status', 'pending')
      .order('created_at', { ascending: false });

    if (error) throw error;

    return data.map(alert => ({
      id: alert.id,
      outletId: alert.outlet_id,
      itemId: alert.item_id,
      type: alert.type,
      message: alert.message,
      status: alert.status,
      createdAt: new Date(alert.created_at),
      acknowledgedAt: alert.acknowledged_at ? new Date(alert.acknowledged_at) : undefined,
      acknowledgedBy: alert.acknowledged_by,
      acknowledgedByName: alert.acknowledged_by_name,
      itemName: alert.item_name,
      itemUnit: alert.item_unit,
      currentStock: alert.current_stock,
      minimumStock: alert.minimum_stock
    }));
  } catch (error) {
    console.error('Error fetching inventory alerts:', error);
    toast.error('Failed to fetch inventory alerts');
    throw error;
  }
}

export async function acknowledgeAlert(alertId: string) {
  try {
    const user = getAuthStore().user;
    if (!user) throw new Error('User not authenticated');

    const { error } = await supabase
      .from('inventory_alerts')
      .update({
        status: 'acknowledged',
        acknowledged_at: new Date().toISOString(),
        acknowledged_by: user.id
      })
      .eq('id', alertId);

    if (error) throw error;
    toast.success('Alert acknowledged');
  } catch (error) {
    console.error('Error acknowledging alert:', error);
    toast.error('Failed to acknowledge alert');
    throw error;
  }
}
export async function fetchInventoryItems(outletId: string) {
  try {
    const { data, error } = await supabase
      .from('inventory_items')
      .select(`
        *,
        purchase_pattern
      `)
      .eq('outlet_id', outletId)
      .eq('is_active', true)
      .order('name');

    if (error) throw error;
    return data.map(item => ({
      id: item.id,
      outletId: item.outlet_id,
      categoryId: item.category_id,
      name: item.name,
      description: item.description,
      sku: item.sku,
      unit: item.unit,
      minimumStock: item.minimum_stock,
      isActive: item.is_active,
      currentStock: item.current_stock || 0,
      averageCost: item.average_cost || 0,
      purchasePattern: item.purchase_pattern ? {
        frequency: item.purchase_pattern.frequency,
        days: item.purchase_pattern.days || [],
        averageQuantity: item.purchase_pattern.average_quantity || 0,
        lastPurchaseDate: item.purchase_pattern.last_purchase_date ? new Date(item.purchase_pattern.last_purchase_date) : undefined,
        nextPurchaseDate: item.purchase_pattern.next_purchase_date ? new Date(item.purchase_pattern.next_purchase_date) : undefined
      } : undefined,
      createdAt: new Date(item.created_at),
      updatedAt: new Date(item.updated_at)
    }));
  } catch (error) {
    console.error('Error fetching inventory items:', error);
    toast.error('Failed to fetch inventory items');
    throw error;
  }
}

export async function createInventoryItem(item: Omit<InventoryItem, 'id' | 'createdAt' | 'updatedAt'>) {
  try {
    const { data, error } = await supabase
      .from('inventory_items')
      .insert([{
        outlet_id: item.outletId,
        category_id: item.categoryId,
        name: item.name,
        description: item.description,
        unit: item.unit,
        minimum_stock: item.minimumStock,
        is_active: true,
        current_stock: 0,
        purchase_pattern: item.purchasePattern ? {
          frequency: item.purchasePattern.frequency,
          days: item.purchasePattern.days || [],
          average_quantity: item.purchasePattern.averageQuantity || 0,
          last_purchase_date: null,
          next_purchase_date: null
        } : null,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      }])
      .select()
      .single();

    if (error) throw error;
    
    return {
      id: data.id,
      outletId: data.outlet_id,
      categoryId: data.category_id,
      name: data.name,
      description: data.description,
      unit: data.unit,
      minimumStock: data.minimum_stock,
      isActive: data.is_active,
      currentStock: data.current_stock || 0,
      purchasePattern: data.purchase_pattern ? {
        frequency: data.purchase_pattern.frequency,
        days: data.purchase_pattern.days || [],
        averageQuantity: data.purchase_pattern.average_quantity || 0,
        lastPurchaseDate: data.purchase_pattern.last_purchase_date ? new Date(data.purchase_pattern.last_purchase_date) : undefined,
        nextPurchaseDate: data.purchase_pattern.next_purchase_date ? new Date(data.purchase_pattern.next_purchase_date) : undefined
      } : undefined,
      createdAt: new Date(data.created_at),
      updatedAt: new Date(data.updated_at)
    };
  } catch (error) {
    console.error('Error creating inventory item:', error);
    toast.error('Failed to create inventory item');
    throw error;
  }
}

export async function updateInventoryItem(id: string, updates: Partial<InventoryItem>) {
  try {
    const { data, error } = await supabase
      .from('inventory_items')
      .update({
        name: updates.name,
        description: updates.description,
        category_id: updates.categoryId,
        unit: updates.unit,
        minimum_stock: updates.minimumStock,
        is_active: updates.isActive,
        purchase_pattern: updates.purchasePattern ? {
          frequency: updates.purchasePattern.frequency,
          days: updates.purchasePattern.days || [],
          average_quantity: updates.purchasePattern.averageQuantity || 0,
          last_purchase_date: updates.purchasePattern.lastPurchaseDate?.toISOString() || null,
          next_purchase_date: updates.purchasePattern.nextPurchaseDate?.toISOString() || null
        } : null,
        updated_at: new Date().toISOString()
      })
      .eq('id', id)
      .select()
      .single();

    if (error) throw error;

    return {
      id: data.id,
      outletId: data.outlet_id,
      categoryId: data.category_id,
      name: data.name,
      description: data.description,
      sku: data.sku,
      unit: data.unit,
      minimumStock: data.minimum_stock,
      isActive: data.is_active,
      currentStock: data.current_stock || 0,
      purchasePattern: data.purchase_pattern ? {
        frequency: data.purchase_pattern.frequency,
        days: data.purchase_pattern.days || [],
        averageQuantity: data.purchase_pattern.average_quantity || 0,
        lastPurchaseDate: data.purchase_pattern.last_purchase_date ? new Date(data.purchase_pattern.last_purchase_date) : undefined,
        nextPurchaseDate: data.purchase_pattern.next_purchase_date ? new Date(data.purchase_pattern.next_purchase_date) : undefined
      } : undefined,
      createdAt: new Date(data.created_at),
      updatedAt: new Date(data.updated_at)
    };
  } catch (error) {
    console.error('Error updating inventory item:', error);
    toast.error('Failed to update inventory item');
    throw error;
  }
}

export async function fetchInventoryTransactions(
  outletId: string,
  itemId?: string,
  startDate?: Date,
  endDate?: Date
) {
  try {
    let query = supabase
      .from('inventory_transactions_with_users')
      .select('*')
      .eq('outlet_id', outletId)
      .order('created_at', { ascending: false });

    if (itemId) {
      query = query.eq('item_id', itemId);
    }
    if (startDate) {
      query = query.gte('created_at', startDate.toISOString());
    }
    if (endDate) {
      query = query.lte('created_at', endDate.toISOString());
    }

    const { data, error } = await query;

    if (error) throw error;

    return data.map(transaction => ({
      id: transaction.id,
      outletId: transaction.outlet_id,
      itemId: transaction.item_id,
      type: transaction.type,
      quantity: transaction.quantity,
      unitPrice: transaction.unit_price,
      totalPrice: transaction.total_price,
      referenceNumber: transaction.reference_number,
      supplier: transaction.supplier,
      notes: transaction.notes,
      createdBy: transaction.created_by,
      createdByName: transaction.created_by_name || 'System',
      createdAt: new Date(transaction.created_at),
      item: {
        name: transaction.item_name,
        unit: transaction.item_unit
      }
    }));
  } catch (error) {
    console.error('Error fetching inventory transactions:', error);
    toast.error('Failed to fetch inventory transactions');
    throw error;
  }
}

export async function createInventoryTransaction(
  transaction: Omit<InventoryTransaction, 'id' | 'createdAt' | 'createdBy'>
) {
  try {
    const user = getAuthStore().user;
    if (!user) throw new Error('User not authenticated');
    
    // Validate quantity
    if (transaction.quantity <= 0) {
      throw new Error('Quantity must be greater than 0');
    }

    // Prepare parameters
    const params = {
      p_item_id: transaction.itemId,
      p_outlet_id: transaction.outletId, 
      p_created_by: user.id,
      p_type: transaction.type,
      p_quantity: transaction.quantity,
      p_unit_price: transaction.unitPrice || null,
      p_reference_number: transaction.referenceNumber || '',
      p_supplier: transaction.supplier || '',
      p_notes: transaction.notes || ''
    };

    // Call database function
    const { data: result, error: transactionError } = await supabase
      .rpc('process_inventory_transaction_v2', params);

    if (transactionError) {
      console.error('Transaction error:', transactionError);
      throw transactionError;
    }

    if (!result) {
      throw new Error('Transaction failed - no result returned');
    }

    // Fetch the complete transaction details
    const { data: transactionDetails, error: detailsError } = await supabase
      .from('inventory_transactions_with_users')
      .select('*')
      .eq('id', result.transaction_id as string)
      .single();

    if (detailsError || !transactionDetails) {
      console.error('Details error:', detailsError);
      throw new Error('Failed to fetch transaction details');
    }

    toast.success('Transaction recorded successfully');
    
    return {
      id: transactionDetails.id,
      outletId: transactionDetails.outlet_id,
      itemId: transactionDetails.item_id,
      type: transactionDetails.type,
      quantity: Number(transactionDetails.quantity),
      unitPrice: transactionDetails.unit_price ? Number(transactionDetails.unit_price) : null,
      totalPrice: transactionDetails.total_price ? Number(transactionDetails.total_price) : null,
      referenceNumber: transactionDetails.reference_number,
      notes: transactionDetails.notes,
      supplier: transactionDetails.supplier,
      createdBy: transactionDetails.created_by,
      createdByName: transactionDetails.created_by_name,
      createdAt: new Date(transactionDetails.created_at),
      item: {
        name: transactionDetails.item_name,
        unit: transactionDetails.item_unit
      }
    };
  } catch (error) {
    console.error('Error processing inventory transaction:', error);
    if (error instanceof Error) {
      toast.error(error.message || 'Failed to process transaction');
    } else {
      toast.error('Failed to process transaction');
    }
    throw error;
  }
}

export async function deleteInventoryItem(id: string) {
  try {
    const user = getAuthStore().user;
    if (!user) {
      throw new Error('User not authenticated');
    }
    
    // First check if there are any transactions
    const { data: transactions, error: checkError } = await supabase
      .from('inventory_transactions')
      .select('id')
      .eq('item_id', id);

    if (checkError) throw checkError;

    if (transactions && transactions.length > 0) {
      throw new Error('Cannot delete item with existing transactions. Please revoke all transactions first.');
    }

    const { data, error } = await supabase
      .rpc('delete_inventory_item', {
        p_item_id: id,
        p_user_id: user.id
      });
    
    if (error) {
      if (error.message.includes('Only administrators can delete')) {
        throw new Error('Only administrators can delete inventory items');
      }
      throw error;
    }

    toast.success('Inventory item deleted successfully');
    return true;
  } catch (error) {
    console.error('Error deleting inventory item:', error);
    if (error instanceof Error) {
      toast.error(error.message);
    } else {
      toast.error('Failed to delete inventory item');
    }
    throw error;
  }
}

export async function revokeTransaction(transactionId: string) {
  try {
    const user = getAuthStore().user;
    if (!user || user.role !== 'admin') {
      throw new Error('Only administrators can revoke transactions');
    }

    const { data: result, error } = await supabase
      .rpc('revert_inventory_transaction', {
        p_transaction_id: transactionId
      });

    if (error) throw error;
    if (!result) throw new Error('Failed to revert transaction');

    toast.success('Transaction revoked successfully');
    return result;
  } catch (error) {
    console.error('Error revoking transaction:', error);
    if (error instanceof Error) {
      toast.error(error.message);
    } else {
      toast.error('Failed to revoke transaction');
    }
    throw error;
  }
}

export async function updateTransaction(id: string, updates: Partial<InventoryTransaction>) {
  try {
    const user = getAuthStore().user;
    if (!user || user.role !== 'admin') {
      throw new Error('Only administrators can edit transactions');
    }

    const { data, error } = await supabase
      .from('inventory_transactions')
      .update({
        reference_number: updates.referenceNumber,
        supplier: updates.supplier,
        notes: updates.notes
      })
      .eq('id', id)
      .select()
      .single();

    if (error) throw error;
    toast.success('Transaction updated successfully');
    return data;
  } catch (error) {
    console.error('Error updating transaction:', error);
    if (error instanceof Error) {
      toast.error(error.message);
    } else {
      toast.error('Failed to update transaction');
    }
    throw error;
  }
}