import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { format } from 'date-fns';
import { InventoryItem, InventoryCategory } from '../types/inventory';
import { startOfDay, endOfDay, startOfWeek, endOfWeek, startOfMonth, endOfMonth, subWeeks, subMonths } from 'date-fns';

export function formatCurrency(amount: number): string {
  return new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  }).format(amount);
}
export function generateSampleCSV(categories: InventoryCategory[]) {
  // Create sample data
  const sampleData = [
    {
      Name: 'Rice',
      Description: 'Basmati Rice',
      Category: categories[0]?.name || '',
      Unit: 'kg',
      'Minimum Stock': 10,
      'Purchase Frequency': 'weekly',
      'Purchase Days': '1;3;5',
      'Average Purchase Quantity': 25
    },
    {
      Name: 'Oil',
      Description: 'Cooking Oil',
      Category: categories[0]?.name || '',
      Unit: 'litre',
      'Minimum Stock': 5,
      'Purchase Frequency': 'monthly',
      'Purchase Days': '1',
      'Average Purchase Quantity': 10
    }
  ];

  return sampleData;
}

export function validateImportData(data: any[], categories: InventoryCategory[]) {
  const errors: string[] = [];
  const validFrequencies = ['daily', 'weekly', 'monthly', 'custom'];

  data.forEach((row, index) => {
    // Required fields
    if (!row.Name) errors.push(`Row ${index + 1}: Name is required`);
    if (!row.Unit) errors.push(`Row ${index + 1}: Unit is required`);
    if (!row.Category) errors.push(`Row ${index + 1}: Category is required`);
    
    // Category validation
    if (row.Category && !categories.some(c => c.name === row.Category)) {
      errors.push(`Row ${index + 1}: Category "${row.Category}" does not exist`);
    }

    // Numeric validations
    if (isNaN(Number(row['Minimum Stock']))) {
      errors.push(`Row ${index + 1}: Minimum Stock must be a number`);
    }
    if (isNaN(Number(row['Average Purchase Quantity']))) {
      errors.push(`Row ${index + 1}: Average Purchase Quantity must be a number`);
    }

    // Purchase pattern validations
    if (row['Purchase Frequency'] && !validFrequencies.includes(row['Purchase Frequency'].toLowerCase())) {
      errors.push(`Row ${index + 1}: Invalid Purchase Frequency. Must be one of: ${validFrequencies.join(', ')}`);
    }

    if (row['Purchase Days']) {
      const days = row['Purchase Days'].split(',').map(Number);
      const frequency = row['Purchase Frequency']?.toLowerCase();
      
      if (frequency === 'weekly' && days.some(d => d < 0 || d > 6)) {
        errors.push(`Row ${index + 1}: Weekly Purchase Days must be between 0-6`);
      }
      if (frequency === 'monthly' && days.some(d => d < 1 || d > 31)) {
        errors.push(`Row ${index + 1}: Monthly Purchase Days must be between 1-31`);
      }
    }
  });

  return errors;
}

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function downloadCSV(data: any[], filename: string) {
  const headers = Object.keys(data[0]);
  const csvContent = [
    headers.join(','),
    ...data.map(row => 
      headers.map(header => {
        const value = row[header]?.toString() || '';
        return `"${value.replace(/"/g, '""')}"`;
      }).join(',')
    )
  ].join('\n');

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function parseCSV(file: File): Promise<any[]> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    
    reader.onload = (event) => {
      try {
        const text = event.target?.result as string;
        const lines = text.split('\n').filter(line => line.trim());
        const headers = lines[0].split(',').map(h => h.trim());
        
        const data = lines.slice(1).map(line => {
          const values = line.split(',').map(v => 
            v.trim().replace(/^"(.*)"$/, '$1').replace(/""/g, '"')
          );
          
          return headers.reduce((obj, header, index) => {
            obj[header] = values[index] || '';
            return obj;
          }, {} as any);
        });

        resolve(data);
      } catch (error) {
        reject(new Error('Invalid CSV format'));
      }
    };
    
    reader.onerror = () => reject(new Error('Failed to read file'));
    reader.readAsText(file);
  });
}

export function formatInventoryForExport(items: InventoryItem[]) {
  try {
    if (!Array.isArray(items)) {
      throw new Error('Invalid input: items must be an array');
    }
    
    return items.map(item => ({
      Name: item.name || '',
      Description: item.description || '',
      Category: item.category?.name || '',
      Unit: item.unit || '',
      'Minimum Stock': item.minimumStock || 0,
      'Current Stock': item.currentStock || 0,
      'Purchase Frequency': item.purchasePattern?.frequency || 'daily',
      'Purchase Days': item.purchasePattern?.days?.join(';') || '',
      'Average Purchase Quantity': item.purchasePattern?.averageQuantity || 0
    }));
  } catch (error) {
    console.error('Error formatting inventory data:', error);
    throw new Error('Failed to format inventory data for export');
  }
}

export function generateOrderId(): string {
  return `ORD-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
}

export function getDateRange(range: 'today' | 'thisWeek' | 'thisMonth' | 'lastWeek' | 'lastMonth' | 'custom', 
  customStart?: Date, customEnd?: Date) {
  const now = new Date();
  
  switch (range) {
    case 'today':
      return {
        start: new Date(now.setHours(0, 0, 0, 0)),
        end: endOfDay(now)
      };
    case 'thisWeek':
      return {
        start: startOfWeek(now, { weekStartsOn: 1 }),
        end: endOfWeek(now)
      };
    case 'thisMonth':
      return {
        start: new Date(now.getFullYear(), now.getMonth(), 1),
        end: endOfMonth(now)
      };
    case 'lastWeek':
      return {
        start: startOfWeek(subWeeks(now, 1), { weekStartsOn: 1 }),
        end: endOfWeek(subWeeks(now, 1), { weekStartsOn: 1 })
      };
    case 'lastMonth':
      return {
        start: new Date(now.getFullYear(), now.getMonth() - 1, 1),
        end: endOfMonth(subMonths(now, 1))
      };
    case 'custom':
      if (!customStart || !customEnd) throw new Error('Custom range requires start and end dates');
      return {
        start: new Date(customStart.setHours(0, 0, 0, 0)),
        end: endOfDay(customEnd)
      };
    default:
      return {
        start: new Date(now.setHours(0, 0, 0, 0)),
        end: endOfDay(now)
      };
  }
}