import React, { useState, useEffect } from 'react';
import { useMenuStore } from '../store/useMenuStore';
import { useOrderStore } from '../store/useOrderStore';
import { useOutletStore } from '../store/useOutletStore';
import { useDailyExpenseStore } from '../store/useDailyExpenseStore';
import { cn, formatCurrency } from '../lib/utils';
import { DateRangeType } from '../types';
import { Calendar, TrendingUp, AlertCircle, DollarSign, Package, ArrowRight, Percent, Receipt } from 'lucide-react';
import { Link } from 'react-router-dom';
import { format, startOfDay, endOfDay } from 'date-fns';
import { LineChart, Line, ResponsiveContainer, XAxis, YAxis, Tooltip, Legend } from 'recharts';

export function Dashboard() {
  const { items, fetchItems } = useMenuStore();
  const { activeOrders, fetchOrders } = useOrderStore();
  const { expenses: dailyExpenses, fetchExpenses: fetchDailyExpenses } = useDailyExpenseStore();
  const { currentOutlet } = useOutletStore();
  const [dateRange, setDateRange] = useState<DateRangeType>('today');
  const [customStartDate, setCustomStartDate] = useState<Date>(new Date());
  const [customEndDate, setCustomEndDate] = useState<Date>(new Date());

  const dateRangeValues = React.useMemo(() => {
    const now = new Date();
    let start = startOfDay(now);
    let end = endOfDay(now);

    switch (dateRange) {
      case 'today':
        // Keep start and end as is
        break;
      case 'yesterday':
        start = startOfDay(new Date(now.setDate(now.getDate() - 1)));
        end = endOfDay(new Date(now.setDate(now.getDate())));
        break;
      case 'thisWeek':
        start = startOfDay(new Date(now.setDate(now.getDate() - now.getDay())));
        end = endOfDay(new Date());
        break;
      case 'lastWeek':
        start = startOfDay(new Date(now.setDate(now.getDate() - now.getDay() - 7)));
        end = endOfDay(new Date(now.setDate(now.getDate() + 6)));
        break;
      case 'thisMonth':
        start = startOfDay(new Date(now.getFullYear(), now.getMonth(), 1));
        end = endOfDay(new Date());
        break;
      case 'lastMonth':
        start = startOfDay(new Date(now.getFullYear(), now.getMonth() - 1, 1));
        end = endOfDay(new Date(now.getFullYear(), now.getMonth(), 0));
        break;
      case 'custom':
        start = startOfDay(customStartDate);
        end = endOfDay(customEndDate);
        break;
    }

    return { start, end };
  }, [dateRange, customStartDate, customEndDate]);

  useEffect(() => {
    if (currentOutlet) {
      fetchItems(currentOutlet);
      fetchOrders(currentOutlet);
      fetchDailyExpenses(currentOutlet, dateRangeValues.start, dateRangeValues.end);
    }
  }, [currentOutlet, fetchItems, fetchOrders, fetchDailyExpenses, dateRangeValues]);

  const filteredOrders = activeOrders.filter(order => {
    const orderDate = new Date(order.createdAt);
    return orderDate >= dateRangeValues.start && 
           orderDate <= dateRangeValues.end && 
           order.outletId === currentOutlet;
  });

  const filteredExpenses = dailyExpenses.filter(expense => {
    const expenseDate = new Date(expense.date);
    return expenseDate >= dateRangeValues.start && 
           expenseDate <= dateRangeValues.end;
  });

  const totalSales = filteredOrders.reduce((sum, order) => sum + order.total, 0);
  const totalOrders = filteredOrders.length;
  const totalExpenses = filteredExpenses.reduce((sum, expense) => sum + expense.amount, 0);
  const netProfit = totalSales - totalExpenses;
  const profitMargin = totalSales > 0 ? (netProfit / totalSales) * 100 : 0;

  // Prepare data for chart
  const salesData = filteredOrders.reduce((acc: Array<{ date: string; sales: number; expenses: number }>, order) => {
    const date = format(new Date(order.createdAt), dateRange === 'today' ? 'HH:mm' : 'MMM dd');
    const existingEntry = acc.find(entry => entry.date === date);
    
    if (existingEntry) {
      existingEntry.sales += Number(order.total);
      existingEntry.sales = Number(existingEntry.sales.toFixed(2));
    } else {
      acc.push({
        date,
        sales: Number(order.total.toFixed(2)),
        expenses: 0
      });
    }
    
    return acc;
  }, []);

  // Add expenses to chart data
  filteredExpenses.forEach(expense => {
    const date = format(new Date(expense.date), dateRange === 'today' ? 'HH:mm' : 'MMM dd');
    const existingEntry = salesData.find(entry => entry.date === date);
    
    if (existingEntry) {
      existingEntry.expenses += Number(expense.amount);
      existingEntry.expenses = Number(existingEntry.expenses.toFixed(2));
    } else {
      salesData.push({
        date,
        sales: 0,
        expenses: Number(expense.amount.toFixed(2))
      });
    }
  });

  // Sort data by date
  salesData.sort((a, b) => {
    const dateA = dateRange === 'today' 
      ? new Date(`1970/01/01 ${a.date}`)
      : new Date(a.date);
    const dateB = dateRange === 'today'
      ? new Date(`1970/01/01 ${b.date}`)
      : new Date(b.date);
    return dateA.getTime() - dateB.getTime();
  });

  if (!currentOutlet) {
    return (
      <div className="flex flex-col items-center justify-center h-[calc(100vh-4rem)]">
        <AlertCircle className="h-16 w-16 text-yellow-500 mb-4" />
        <h2 className="text-2xl font-semibold text-gray-900 mb-2">No Outlet Selected</h2>
        <p className="text-gray-600 mb-4">Please select an outlet to view dashboard</p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-4">
          <h1 className="text-2xl font-semibold text-gray-900">Dashboard</h1>
          <div className="flex items-center gap-4">
            <Link
              to="/daily-expenses"
              className={cn(
                "inline-flex items-center px-4 py-2 border rounded-lg text-sm font-medium transition-colors",
                "border-indigo-600 text-indigo-600 hover:bg-indigo-50"
              )}
            >
              <Receipt className="h-5 w-5 mr-2" />
              Daily Expenses
            </Link>
            <Link
              to="/reports"
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-lg text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 transition-colors"
            >
              <TrendingUp className="h-5 w-5 mr-2" />
              View Reports
            </Link>
          </div>
        </div>
        <div className="flex items-center gap-4">
          <select
            value={dateRange}
            onChange={(e) => setDateRange(e.target.value as DateRangeType)}
            className="border rounded-md px-3 py-2"
          >
            <option value="today">Today</option>
            <option value="thisWeek">This Week</option>
            <option value="thisMonth">This Month</option>
            <option value="lastWeek">Last Week</option>
            <option value="lastMonth">Last Month</option>
            <option value="custom">Custom Range</option>
          </select>

          {dateRange === 'custom' && (
            <div className="flex items-center gap-2">
              <input
                type="date"
                value={format(customStartDate, 'yyyy-MM-dd')}
                onChange={(e) => setCustomStartDate(new Date(e.target.value))}
                className="border rounded-md px-3 py-2"
              />
              <span>to</span>
              <input
                type="date"
                value={format(customEndDate, 'yyyy-MM-dd')}
                onChange={(e) => setCustomEndDate(new Date(e.target.value))}
                className="border rounded-md px-3 py-2"
              />
            </div>
          )}
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <div className="bg-gradient-to-br from-green-50 to-green-100 rounded-xl border border-green-200 p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-sm font-medium text-green-800">Total Sales</h3>
            <span className="p-2 bg-green-200 rounded-lg">
              <TrendingUp className="h-5 w-5 text-green-700" />
            </span>
          </div>
          <div className="space-y-2">
            <p className="text-3xl font-bold text-green-700">
              {formatCurrency(totalSales)}
            </p>
            <div className="flex items-center justify-between text-sm">
              <span className="text-green-600">
                {totalOrders} orders
              </span>
            </div>
          </div>
        </div>

        <div className="bg-gradient-to-br from-red-50 to-red-100 rounded-xl border border-red-200 p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-sm font-medium text-red-800">Total Expenses</h3>
            <span className="p-2 bg-red-200 rounded-lg">
              <DollarSign className="h-5 w-5 text-red-700" />
            </span>
          </div>
          <div className="space-y-2">
            <p className="text-3xl font-bold text-red-700">
              {formatCurrency(totalExpenses)}
            </p>
            <div className="flex items-center justify-between text-sm">
              <span className="text-red-600">
                {filteredExpenses.length} transactions
              </span>
            </div>
          </div>
        </div>

        <div className="bg-gradient-to-br from-indigo-50 to-indigo-100 rounded-xl border border-indigo-200 p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-sm font-medium text-indigo-800">Net Profit</h3>
            <span className="p-2 bg-indigo-200 rounded-lg">
              <TrendingUp className="h-5 w-5 text-indigo-700" />
            </span>
          </div>
          <div className="space-y-2">
            <p className="text-3xl font-bold text-indigo-700">
              {formatCurrency(netProfit)}
            </p>
            <div className="flex items-center justify-between text-sm">
              <span className="text-indigo-600">
                For selected period
              </span>
            </div>
          </div>
        </div>

        <div className="bg-gradient-to-br from-purple-50 to-purple-100 rounded-xl border border-purple-200 p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-sm font-medium text-purple-800">Profit Margin</h3>
            <span className="p-2 bg-purple-200 rounded-lg">
              <Percent className="h-5 w-5 text-purple-700" />
            </span>
          </div>
          <div className="space-y-2">
            <p className="text-3xl font-bold text-purple-700">
              {profitMargin.toFixed(1)}%
            </p>
            <div className="flex items-center justify-between text-sm">
              <span className="text-purple-600">
                Average for period
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="bg-white rounded-xl shadow-sm border border-gray-100 overflow-hidden">
          <div className="p-6 border-b border-gray-100">
            <h3 className="text-lg font-medium text-gray-900 flex items-center gap-2">
              <TrendingUp className="h-5 w-5 text-indigo-500" />
              Sales Trend
            </h3>
          </div>
          <div className="h-[300px]">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={salesData}>
                <XAxis 
                  dataKey="date"
                  interval={0}
                  angle={dateRange !== 'today' ? -45 : 0}
                  textAnchor={dateRange !== 'today' ? 'end' : 'middle'}
                  height={60}
                />
                <YAxis 
                  yAxisId="left"
                  tickFormatter={(value) => `₹${Math.abs(value) >= 1000 ? `${(value/1000).toFixed(1)}K` : value}`}
                />
                <Tooltip formatter={(value: number) => formatCurrency(value)} />
                <Legend />
                <Line 
                  type="monotone" 
                  dataKey="sales"
                  name="Sales"
                  stroke="#4ADE80"
                  strokeWidth={2}
                  dot={dateRange !== 'today'}
                  yAxisId="left"
                />
                <Line 
                  type="monotone" 
                  dataKey="expenses"
                  name="Expenses"
                  stroke="#F87171"
                  strokeWidth={2}
                  dot={dateRange !== 'today'}
                  yAxisId="left"
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div className="bg-white rounded-xl shadow-sm border border-gray-100 overflow-hidden">
          <div className="p-6 border-b border-gray-100">
            <h3 className="text-lg font-medium text-gray-900 flex items-center gap-2">
              <Package className="h-5 w-5 text-indigo-500" />
              Menu Performance
            </h3>
          </div>
          <div className="p-4">
            <div className="space-y-4">
              {items
                .sort((a, b) => {
                  const aOrders = filteredOrders.filter(order => 
                    order.items.some(item => item.menuItem.id === a.id)
                  ).length;
                  const bOrders = filteredOrders.filter(order => 
                    order.items.some(item => item.menuItem.id === b.id)
                  ).length;
                  return bOrders - aOrders;
                })
                .slice(0, 5)
                .map((item) => {
                  const itemOrders = filteredOrders.filter(order => 
                    order.items.some(orderItem => orderItem.menuItem.id === item.id)
                  );
                  const revenue = itemOrders.reduce((sum, order) => {
                    const orderItems = order.items.filter(orderItem => orderItem.menuItem.id === item.id);
                    return sum + orderItems.reduce((itemSum, orderItem) => itemSum + orderItem.totalAmount, 0);
                  }, 0);

                  return (
                    <div key={item.id} className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
                      <div className="flex-1">
                        <h4 className="font-medium text-gray-900">{item.name}</h4>
                        <p className="text-sm text-gray-500">{item.category}</p>
                      </div>
                      <div className="text-right">
                        <p className="font-medium text-indigo-600">{formatCurrency(revenue)}</p>
                        <p className="text-sm text-gray-500">{itemOrders.length} orders</p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}