import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { useExpenseStore } from '../../store/useExpenseStore';
import { ExpenseCategory, ExpenseSubcategory } from '../../types/expense';
import { formatCurrency } from '../../lib/utils';
import toast from 'react-hot-toast';

interface ExpenseModalProps {
  isOpen: boolean;
  onClose: () => void;
  expense?: any;
  categories: ExpenseCategory[];
  types: ExpenseType[];
  outletId: string;
}

export function ExpenseModal({ isOpen, onClose, expense, categories, types, outletId }: ExpenseModalProps) {
  const { addExpense, updateExpense } = useExpenseStore();
  const [formData, setFormData] = useState({
    categoryId: expense?.categoryId || '',
    subcategoryId: expense?.subcategoryId || '',
    quantity: expense?.quantity?.toString() || expense?.subcategory?.quantity?.toString() || '0',
    typeId: expense?.typeId || '',
    amount: expense?.amount?.toString() || '',
    taxAmount: expense?.taxAmount?.toString() || '0',
    description: expense?.description || '',
    date: expense?.date ? new Date(expense.date).toISOString().split('T')[0] : new Date().toISOString().split('T')[0],
    paymentMethod: expense?.paymentMethod || 'cash',
    referenceNumber: expense?.referenceNumber || '',
  });

  // Get subcategories for selected category
  const selectedType = types?.find(t => t.id === formData.typeId);
  const selectedCategory = categories.find(c => c.id === formData.categoryId && c.typeId === formData.typeId);
  const subcategories = selectedCategory?.subcategories || [];
  const selectedSubcategory = subcategories.find(s => s.id === formData.subcategoryId);

  useEffect(() => {
    if (isOpen && expense) {
      setFormData({
        quantity: expense.quantity?.toString() || '0',
        typeId: expense.type?.id || expense.category?.typeId || types[0]?.id || '',
        categoryId: expense.categoryId || '',
        subcategoryId: expense.subcategoryId || '',
        amount: expense.amount.toString(),
        taxAmount: expense.taxAmount.toString(),
        description: expense.description || '',
        date: new Date(expense.date).toISOString().split('T')[0],
        paymentMethod: expense.paymentMethod,
        referenceNumber: expense.referenceNumber || '',
      });
    } else if (isOpen) {
      // Reset form for new expense
      setFormData({
        categoryId: '',
        subcategoryId: '',
        typeId: '',
        quantity: '0',
        amount: '',
        taxAmount: '0',
        description: '',
        date: new Date().toISOString().split('T')[0],
        paymentMethod: 'cash',
        referenceNumber: '',
      });
    }
  }, [isOpen, expense, categories]);

  // Update quantity when subcategory changes
  useEffect(() => {
    if (selectedSubcategory && !expense) {
      setFormData(prev => ({
        ...prev,
        quantity: selectedSubcategory.quantity?.toString() || prev.quantity || '0'
      }));
    }
  }, [selectedSubcategory, expense]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (!formData.typeId || !formData.categoryId || !formData.subcategoryId) {
        toast.error('Please select type, category, and subcategory');
        return;
      }

      const quantity = Number(formData.quantity);
      if (isNaN(quantity) || quantity < 0) {
        toast.error('Please enter a valid quantity');
        return;
      }

      if (!formData.amount || Number(formData.amount) <= 0) {
        toast.error('Please enter a valid amount');
        return;
      }


      if (!formData.taxAmount) {
        setFormData(prev => ({ ...prev, taxAmount: '0' }));
      }

      const selectedType = types.find(t => t.id === formData.typeId);
      const selectedCategory = categories.find(c => c.id === formData.categoryId);
      const selectedSubcategory = selectedCategory?.subcategories?.find(s => s.id === formData.subcategoryId);

      if (!selectedType || !selectedCategory || !selectedSubcategory) {
        toast.error('Invalid type, category or subcategory selection');
        return;
      }
      const expenseData = {
        ...formData,
        outletId,
        amount: Number(formData.amount),
        taxAmount: Number(formData.taxAmount || 0),
        quantity: quantity,
        date: new Date(formData.date),
        categoryId: selectedCategory.id,
        subcategoryId: selectedSubcategory.id,
        type: selectedType ? {
          id: selectedType.id,
          name: selectedType.name,
          description: selectedType.description
        } : null,
        category: selectedCategory ? {
          id: selectedCategory.id,
          name: selectedCategory.name,
          description: selectedCategory.description,
          typeId: selectedType.id
        } : null,
        subcategory: selectedSubcategory ? {
          id: selectedSubcategory.id,
          name: selectedSubcategory.name,
          description: selectedSubcategory.description,
          quantity: selectedSubcategory.quantity,
          unit: selectedSubcategory.unit
        } : null
      };

      console.log('Submitting expense data:', expenseData);

      if (expense) {
        await updateExpense(expense.id, expenseData);
        toast.success('Expense updated successfully');
      } else {
        await addExpense(expenseData);
        toast.success('Expense created successfully');
      }
      onClose();
    } catch (error) {
      console.error('Error saving expense:', error);
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error('Failed to save expense');
      }
    }
  };

  const handleCategoryChange = (categoryId: string) => {
    setFormData(prev => ({
      ...prev,
      categoryId,
      subcategoryId: '' // Reset subcategory when category changes
    }));
  };

  if (!isOpen) return null;

  if (categories.length === 0) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg p-6 w-full max-w-md">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold">Cannot Add Expense</h2>
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
              <X className="h-6 w-6" />
            </button>
          </div>
          <p className="text-gray-600 mb-6">Please add at least one expense category before creating expenses.</p>
          <div className="flex justify-end">
            <button
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl shadow-xl w-full max-w-2xl max-h-[90vh] overflow-y-auto mx-4">
        <div className="flex justify-between items-center mb-4">
          <div className="p-6 pb-0">
            <h2 className="text-2xl font-semibold text-gray-900">
              {expense ? 'Edit Expense' : 'Add New Expense'}
            </h2>
            <p className="mt-1 text-sm text-gray-500">
              Fill in the details below to {expense ? 'update the' : 'record a new'} expense
            </p>
          </div>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="h-6 w-6" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-6 pt-0 space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700">Type</label>
              <select
                value={formData.typeId}
                onChange={(e) => {
                  setFormData({ 
                    ...formData, 
                    typeId: e.target.value,
                    categoryId: '',
                    subcategoryId: ''
                  });
                }}
                className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                required
              >
                <option value="">Select Type</option>
                {types.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Category</label>
              <select
                value={formData.categoryId}
                onChange={(e) => setFormData({ ...formData, categoryId: e.target.value, subcategoryId: '' })}
                className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                disabled={!formData.typeId}
                required
              >
                <option value="">Select Category</option>
                {categories.filter(c => c.typeId === formData.typeId).map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Subcategory</label>
              <select
                required
                value={formData.subcategoryId}
                onChange={(e) => setFormData({ ...formData, subcategoryId: e.target.value })}
                className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                disabled={!formData.categoryId}
              >
                <option value="">Select Subcategory</option>
                {subcategories.map((subcategory) => (
                  <option key={subcategory.id} value={subcategory.id}>
                    {subcategory.name}
                  </option>
                ))}
                {subcategories.length === 0 && formData.categoryId && (
                  <option value="" disabled>No subcategories available</option>
                )}
              </select>
              {formData.categoryId && subcategories.length === 0 && (
                <p className="mt-1 text-sm text-red-600">
                  Please add subcategories to the selected category first
                </p>
              )}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Quantity ({selectedSubcategory?.unit || 'units'})</label>
              <input
                type="number"
                step="0.01"
                min="0"
                value={formData.quantity}
                onChange={(e) => setFormData({ ...formData, quantity: e.target.value })}
                className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Amount</label>
              <input
                type="number"
                step="0.01"
                min="0"
                value={formData.amount}
                onChange={(e) => setFormData({ ...formData, amount: e.target.value })}
                className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Tax Amount</label>
              <input
                type="number"
                step="0.01"
                min="0"
                value={formData.taxAmount}
                onChange={(e) => setFormData({ ...formData, taxAmount: e.target.value })}
                className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Date</label>
              <input
                type="date"
                value={formData.date}
                onChange={(e) => setFormData({ ...formData, date: e.target.value })}
                className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Payment Method</label>
              <select
                value={formData.paymentMethod}
                onChange={(e) => setFormData({ ...formData, paymentMethod: e.target.value })}
                className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                required
              >
                <option value="cash">Cash</option>
                <option value="card">Card</option>
                <option value="upi">UPI</option>
                <option value="bank_transfer">Bank Transfer</option>
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Reference Number</label>
              <input
                type="text"
                value={formData.referenceNumber}
                onChange={(e) => setFormData({ ...formData, referenceNumber: e.target.value })}
                className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
            </div>
          </div>

          <div className="col-span-2">
            <label className="block text-sm font-medium text-gray-700">Description</label>
            <textarea
              value={formData.description}
              onChange={(e) => setFormData({ ...formData, description: e.target.value })}
              rows={3}
              className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>

          <div className="flex justify-end space-x-3 pt-4 border-t">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 rounded-lg text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 transition-colors"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 border border-transparent rounded-lg text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 transition-colors"
            >
              {expense ? 'Update' : 'Add'} Expense
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}